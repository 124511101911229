import React, { useState } from "react";
import { Link } from "react-router-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import profimg from "../images/pro.png";
import { Avatar, Drawer } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { history } from "../../../../reducers";
import { delete_task, update_task } from "../actions/action-task";
import { useDispatch } from "react-redux";
import configureStore from "../../../../store/configureStore";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  avatar: {
    textTransform: "uppercase",
  },
}));

export default function TaskCard(props) {
  const [isDrawerOpen, setIsDrawerOPen] = useState(false);
  const { activeOuid } = store.getState().permission;
  const dispatch = useDispatch();
  const classes = useStyles();

  const convertNumberToDate = (defaultDate) => {
    console.log("defaultDate", defaultDate);

    let year = defaultDate.substring(0, 4);
    let month = defaultDate.substring(4, 6);
    let date = defaultDate.substring(6, 8);

    console.log("year", year);
    console.log("month", month);
    console.log("date", date);
    console.log("convertNumberToDate", year + "-" + month + "-" + date);
    // return year + "-" + month + "-" + date;
    return date + "-" + month + "-" + year;
  };

  const handleComplete = (taskId) => {
    var taskData = props.item;
    console.log("complete", taskData);
    taskData.status = "completed";
    console.log("leadData", taskId);
    dispatch(update_task(taskData, activeOuid, taskId));
  };

  const handleDetele = () => {
    console.log("props.item.username delete", props.item.id);
    dispatch(delete_task(activeOuid, props.item.id));
  };

  return (
    <div className="schedule_card mb">
      <div className="profile_top">
        <Link
          to={`/${activeOuid}/task-details/${props.item.id}`}
          className="profile_left"
        >
          <div className="profile_img">
            <Avatar
              className={classes.avatar}
              style={{
                backgroundColor: "#214B87",
              }}
            >
              {props.item.leadContactFullName &&
                props.item.leadContactFullName.charAt(0)}
            </Avatar>
          </div>
          <div className="profile_details">
            <h6 className="profile_name">{props.item.leadContactFullName}</h6>
            {/* <div className="profile_company_name">{props.item.taskDetail}</div> */}
          </div>
        </Link>

        {/* <div className="profile_right">
          <div className="schedule_check_icon">
            {
              props.item.status === "open" && <CheckBoxOutlineBlankIcon />
            }
            {
              props.item.status === "completed" && <CheckBoxIcon />
            }
          </div>
        </div> */}
      </div>
      <Link
        to={`/${activeOuid}/task-details/${props.item.id}`}
        className="card_desc mt"
      >
        {props.item.taskDetail}
      </Link>
      <div className="schedule_card_bottom">
        <div className="schedule_card_bottom_left">
          <span className="schedule_card_date">
            {props.item && convertNumberToDate(`${props.item.taskDateTime}`)}
          </span>
        </div>
        <div className="schedule_card_bottom_right">
          <button
            className="card_option_btn"
            onClick={() => setIsDrawerOPen(true)}
          >
            <MoreVertIcon />
          </button>
          <Drawer
            className="edit_option_btn_drawer"
            anchor="bottom"
            open={isDrawerOpen}
            onClose={() => setIsDrawerOPen(false)}
          >
            <div className="edit_option_head">options</div>
            <ul className="edit_option_tools_wrap">
              <li
                className="edit_option_tools_item"
                onClick={() => setIsDrawerOPen(false)}
              >
                <div className="edit_option">
                  <EditIcon
                    onClick={() =>
                      history.push(`/${activeOuid}/edit-task/${props.item.id}`)
                    }
                  />
                  <span className="edit_option_tools_item_text">Edit</span>
                </div>
              </li>
              <li
                className="edit_option_tools_item"
                onClick={() => setIsDrawerOPen(false)}
              >
                <div className="edit_option">
                  <DeleteIcon onClick={() => handleDetele()} />
                  <span className="edit_option_tools_item_text">Delete</span>
                </div>  
              </li>
              <li
                className="edit_option_tools_item"
                onClick={() => setIsDrawerOPen(false)}
              >
                <div className="edit_option">
                  <CheckCircleIcon
                    onClick={() => handleComplete(props.item.id)}
                  />
                  <span className="edit_option_tools_item_text">completed</span>
                </div>
              </li>
            </ul>
          </Drawer>
        </div>
      </div>
    </div>
  );
}
