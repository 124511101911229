import React, { useState } from "react";
import { Link } from "react-router-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import profimg from "../images/pro.png";
import { Drawer } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import configureStore from "../../../../store/configureStore";
import { delete_document } from "../actions/action-document";
import { history } from "../../../../reducers";
import { useDispatch } from "react-redux";
import EditIcon from "@material-ui/icons/Edit";
import GetAppIcon from "@material-ui/icons/GetApp";
import axios from "axios";
import fileIcon from "../images/file.png";

const store = configureStore();

export default function DocsCard(props) {
  const [isDrawerOpen, setIsDrawerOPen] = useState(false);
  const { activeOuid } = store.getState().permission;
  const dispatch = useDispatch();

  const handleDetele = () => {
    console.log("props.item.username delete", props.item.id);
    dispatch(delete_document(props.item.id, activeOuid));
  };

  function downloadFromUrl(url, name) {
    fetch(url)
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = window.document.createElement("a");
        a.style.display = "none";
        a.href = url;
        // the filename you want
        a.download = name;
        window.document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        alert("File has been downloaded"); // or you know, something with better UX...
      })
      .catch(() => alert("oh no!"));
  }

  var documentKey = props.item.documentKey;
  var fileName = documentKey.substring(documentKey.lastIndexOf("/") + 1);

  return (
    <div className="docs_card_wrap card_wrap">
      <div className="docs_card_header">
        <Link
          to={`/${activeOuid}/contact-profile/${props.item.leadId}`}
          className="file_name"
        >
          {props.item.leadContactFullName}
        </Link>
        <div className="schedule_card_bottom">
          <div className="schedule_card_bottom_right">
            <button
              className="option_btn"
              onClick={() => setIsDrawerOPen(true)}
            >
              <MoreVertIcon />
            </button>
            <Drawer
              className="edit_option_btn_drawer"
              anchor="bottom"
              open={isDrawerOpen}
              onClose={() => setIsDrawerOPen(false)}
            >
              <div className="edit_option_head">options</div>
              <ul className="edit_option_tools_wrap">
                <li
                  className="edit_option_tools_item"
                  onClick={() => setIsDrawerOPen(false)}
                >
                  <div className="edit_option">
                    <EditIcon
                      onClick={() =>
                        history.push(`/${activeOuid}/edit-docs/${props.item.id}`)
                      }
                    />
                    <span className="edit_option_tools_item_text">Edit</span>
                  </div>
                </li>
                <li
                  className="edit_option_tools_item"
                  onClick={() => setIsDrawerOPen(false)}
                >
                  <div className="edit_option">
                    <DeleteIcon onClick={() => handleDetele()} />
                    <span className="edit_option_tools_item_text">Delete</span>
                  </div>
                </li>
                <li
                  className="edit_option_tools_item"
                  onClick={() => setIsDrawerOPen(false)}
                >
                  <div className="edit_option">
                    <GetAppIcon
                      onClick={() => {
                        downloadFromUrl(
                          props.item.documentDownloadURL,
                          props.item.documentKey
                        );
                      }}
                    />
                    <span className="edit_option_tools_item_text">Download</span>
                  </div>
                </li>
              </ul>
            </Drawer>
          </div>
        </div>
      </div>
      <div className="docs_card_body">
        <div>
          <div className="docs_card_file_preview">
            <img src={fileIcon} />
          </div>
        </div>
        <div>
          <p className="card_desc mt">{fileName}</p>
          {/* <p className="card_desc ">{props.item.description}</p> */}
        </div>
      </div>
    </div>
  );
}
