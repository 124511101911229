import { Button, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import Footer from "./Footer";
import HeaderInner from "./HeaderInner";
import configureStore from "../../../store/configureStore";
import { get_lead_details, update_lead } from "./actions/action-lead";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../../reducers";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "../../common/Loader";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
  },
  input: {
    marginBottom: 10,
    background: "#fff",
  },
}));

function EditLead() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeOuid, activeOuidChain } = store.getState().permission;

  const { leaddetails, showGlobalLoader } = useSelector((state) => state.lead);

  const [state, setState] = React.useState({
    id: "",
    companyName: "",
    contactFullName: " ",
    contactPhoneNumber: "",
    contactEmail: "",
    companyWebsite: "",
    handler: "",
    status: "",
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.id]: event.target.value });
    console.log("lead", state);
  };

  console.log("state.leadId", state.id);

  const handleSubmit = () => {
    console.log("Handle submit");
    dispatch(update_lead(state, activeOuid, state.id));
    history.push(`/${activeOuid}/contacts`);
  };

  useEffect(() => {
    let leadId;
    leadId = window.location.pathname.split("/");
    leadId = leadId[3];

    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_lead_details(leadId, activeOuid));
      console.log(store.getState().lead.leaddetails);
      setState({
        ...state,
        id: store.getState().lead.leaddetails.id,
        companyName: store.getState().lead.leaddetails.companyName,
        contactFullName: store.getState().lead.leaddetails.contactFullName,
        contactPhoneNumber:
          store.getState().lead.leaddetails.contactPhoneNumber,
        contactEmail: store.getState().lead.leaddetails.contactEmail,
        companyWebsite: store.getState().lead.leaddetails.companyWebsite,
        status: store.getState().lead.leaddetails.status,
        handler: store.getState().lead.leaddetails.handler,
      });
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Edit Lead" />
      <div className="AppPage container--narrow mt">
        <h1 className="primary_heading">Leads Details</h1>
        <p className="sub_text">
          Sales leads can be tracked through CRM system, enabling sales teams to
          input, track and analyze data for leads in one place.
        </p>

        {showGlobalLoader ? (
          <Loader />
        ) : (
          <form className="add_wrap" onSubmit={() => handleSubmit()}>
            <TextField
              id="contactFullName"
              label="Full Name"
              defaultValue={leaddetails.contactFullName}
              fullWidth
              variant="outlined"
              className={classes.input}
              onChange={handleChange}
              required
            />
            <TextField
              id="companyName"
              label="Company Name"
              fullWidth
              variant="outlined"
              defaultValue={leaddetails.companyName}
              className={classes.input}
              onChange={handleChange}
            />
            <TextField
              id="contactPhoneNumber"
              label="Phone Number"
              fullWidth
              variant="outlined"
              defaultValue={leaddetails.contactPhoneNumber}
              className={classes.input}
              onChange={handleChange}
              required
            />
            <TextField
              id="contactEmail"
              label="Email Address"
              fullWidth
              variant="outlined"
              defaultValue={leaddetails.contactEmail}
              className={classes.input}
              onChange={handleChange}
              required
            />
            <TextField
              id="companyWebsite"
              label="Website"
              fullWidth
              variant="outlined"
              defaultValue={leaddetails.companyWebsite}
              className={classes.input}
              onChange={handleChange}
            />
            {/* <button
            className="w-100 primary_btn"
            onClick={() => handleSubmit(event)}
          >
            ADD lead
          </button> */}
            <Button
              type="submit"
              className="primary_btn"
              fullWidth
              variant="contained"
              color="primary"
              // onClick={() => handleSubmit()}
            >
              Update lead
            </Button>
          </form>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default EditLead;
