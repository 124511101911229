import {
  CREATE_MEETING_REQUESTED,
  CREATE_MEETING_SUCCESS,
  CREATE_MEETING_ERRORED,
  GET_MEETINGS_REQUESTED,
  GET_MEETINGS_SUCCESS,
  GET_MEETINGS_ERRORED,
  GET_MEETING_DETAILS_REQUESTED,
  GET_MEETING_DETAILS_SUCCESS,
  GET_MEETING_DETAILS_ERRORED,
  UPDATE_MEETING_REQUESTED,
  UPDATE_MEETING_SUCCESS,
  UPDATE_MEETING_ERRORED,
  DELETE_MEETING_REQUESTED,
  DELETE_MEETING_SUCCESS,
  DELETE_MEETING_ERRORED,
} from "../constant/action-types";

import axios from "axios";

import { showSnackbar } from "../../../../actions/action-snackbar";

import configureStore from "../../../../store/configureStore";

const store = configureStore();

export const create_meeting_requested = () => ({
  type: CREATE_MEETING_REQUESTED,
});

export const create_meeting_success = () => ({
  type: CREATE_MEETING_SUCCESS,
});

export const create_meeting_errored = (error) => ({
  type: CREATE_MEETING_ERRORED,
  error,
});

export const get_meeting_requested = () => ({
  type: GET_MEETINGS_REQUESTED,
});

export const get_meeting_success = (payload) => ({
  type: GET_MEETINGS_SUCCESS,
  payload,
});

export const get_meeting_errored = (error) => ({
  type: GET_MEETINGS_ERRORED,
  error,
});

export const get_meeting_details_requested = () => ({
  type: GET_MEETING_DETAILS_REQUESTED,
});

export const get_meeting_details_success = (payload) => ({
  type: GET_MEETING_DETAILS_SUCCESS,
  payload,
});

export const get_meeting_details_errored = (error) => ({
  type: GET_MEETING_DETAILS_ERRORED,
  error,
});

export const update_meeting_requested = () => ({
  type: UPDATE_MEETING_REQUESTED,
});

export const update_meeting_success = (payload) => ({
  type: UPDATE_MEETING_SUCCESS,
  payload,
});

export const update_meeting_errored = (error) => ({
  type: UPDATE_MEETING_ERRORED,
  error,
});
export const delete_meeting_requested = () => ({
  type: DELETE_MEETING_REQUESTED,
});

export const delete_meeting_success = (payload) => ({
  type: DELETE_MEETING_SUCCESS,
  payload,
});

export const delete_meeting_errored = (error) => ({
  type: DELETE_MEETING_ERRORED,
  error,
});

export const create_meeting = (payload, activeOuid) => async (dispatch) => {
  dispatch(create_meeting_requested());
  console.log(`Create meeting`);
  const { crmApiEndpoint, credentials } = store.getState().jwt.api;
  if (crmApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${crmApiEndpoint}/meetings`;
    const method = "post";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        console.log("meeting created Successfully");
        dispatch(showSnackbar("meeting created Successfully", "success"));
        dispatch(create_meeting_success());
        dispatch(get_meeting("", activeOuid));
      })
      .catch((err) => {
        console.log("meeting creation Failed");
        // console.error('ErrorLog : ', JSON.stringify(err));
        dispatch(showSnackbar("meeting creation Failed", "error"));
        dispatch(create_meeting_errored(err));
      });
  }
  return null;
};

export const update_meeting =
  (payload, activeOuid, meetingId) => async (dispatch) => {
    dispatch(update_meeting_requested());
    console.log(`update_meeting`);

    const { crmApiEndpoint, credentials } = store.getState().jwt.api;
    if (crmApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${crmApiEndpoint}/meetings/id/${meetingId}`;
      const { activeOuid, activeOuidChain } = store.getState().permission;
      const method = "put";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("meeting Updation Success");
          dispatch(showSnackbar("meeting Updated Successfully", "success"));
          dispatch(update_meeting_success());
        })
        .catch((err) => {
          console.log("meeting updation Failed");
          dispatch(showSnackbar("meeting updation Failed", "error"));
          dispatch(update_meeting_errored(err));
        });
    }
    return null;
  };

export const get_meeting = (query, activeOuid) => async (dispatch) => {
  dispatch(get_meeting_requested());
  const { crmApiEndpoint, credentials } = store.getState().jwt.api;
  if (crmApiEndpoint && credentials) {
    console.log(`hrmApiEndpoint`);

    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${crmApiEndpoint}/meetings${query}`;
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get meeting Success", response.data);
        dispatch(get_meeting_success(response.data.items));
      })
      .catch((err) => {
        console.log("Get meeting Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get meeting Failed", "error"));
        }
        dispatch(get_meeting_errored(err));
      });
  }
  return null;
};

export const get_meeting_details =
  (meetingId, activeOuid) => async (dispatch) => {
    dispatch(get_meeting_details_requested());
    const { crmApiEndpoint, credentials } = store.getState().jwt.api;
    console.log(`crmApiEndpoint`, crmApiEndpoint);
    console.log(`credentials`, credentials);

    if (crmApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${crmApiEndpoint}/meetings/id/${meetingId}`;

      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get meeting Success", response.data);
          dispatch(get_meeting_details_success(response.data));
        })
        .catch((err) => {
          console.log("Get meeting Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get meeting Failed", "error"));
          }
          dispatch(get_meeting_details_errored(err));
        });
    }
    return null;
  };

export const delete_meeting = (activeOuid, meetingId) => async (dispatch) => {
  dispatch(delete_meeting_requested());
  console.log(`delete meeting`);
  const { crmApiEndpoint, credentials } = store.getState().jwt.api;
  if (crmApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${crmApiEndpoint}/meetings/id/${meetingId}`;
    const method = "delete";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Delete meeting Success");
        dispatch(showSnackbar("meeting deleted successfully", "success"));
        dispatch(delete_meeting_success());
        dispatch(get_meeting("", activeOuid));
      })
      .catch((err) => {
        if (err.response) {
          console.log(
            "Delete meeting Failed :",
            JSON.stringify(err.response.data)
          );
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Delete meeting", "error"));
        }
        dispatch(delete_meeting_errored(err));
      });
  }
  return null;
};
