import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "./Footer";
import HeaderInner from "./HeaderInner";
import { history } from "../../../reducers";
import Loader from "../../common/Loader";
import ScheduleCard from "./common/ScheduleCard";
import configureStore from "../../../store/configureStore";
import { get_task } from "./actions/action-task";
import TaskCard from "./common/TaskCard";
import CommonSnackBar from "../../common/CommonSnackBar";

const store = configureStore();

export default function Tasks() {
  const { activeOuid } = store.getState().permission;
  const dispatch = useDispatch();
  const { tasks, showGlobalLoader } = useSelector((state) => state.task);
  function renderTasksList(tasks) {
    console.log("task", tasks);
    if (tasks) {
      return tasks.map((item) => <TaskCard key={item.id} item={item} />);
    } else {
      return <>No tasks found</>;
    }
  }

  useEffect(() => {
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_task("?status=open", activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Tasks " onClick={() => {history.push("/")}}/>
      <div className="AppPage container--narrow mt pb">
        {showGlobalLoader ? <Loader /> : renderTasksList(tasks)}
        <div className="AppFloatingButtonWrapper">
          <button
            className="w-100  secondary_btn"
            onClick={() => history.push(`/${activeOuid}/add-task`)}
          >
            Add Task
          </button>
        </div>
      </div>
      <Footer />
      <CommonSnackBar />
    </div>
  );
}
