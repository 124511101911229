import React, { useEffect } from "react";
import HeaderInner from "./HeaderInner";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Button, TextField } from "@material-ui/core";
import configureStore from "../../../store/configureStore";
import { useDispatch, useSelector } from "react-redux";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { create_meeting } from "./actions/action-meeting";
import Footer from "./Footer";
import { get_lead, get_lead_details } from "./actions/action-lead";
import { history } from "../../..//reducers";
import CommonSnackBar from "../../common/CommonSnackBar";
import { KeyboardDatePicker } from "@material-ui/pickers";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
  },
  input: {
    marginBottom: 10,
    background: "#fff",
    width: "100%",
  },
  Select: {
    marginBottom: 10,
    background: "#fff",
    width: "100%",
  },
  formControl: {
    marginBottom: 10,
    background: "#fff",
    width: "100%",
  },
}));

export default function AddMeeting() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeOuid, activeOuidChain } = store.getState().permission;
  const { meetings, showGlobalLoader } = store.getState().meeting;
  const { leads } = useSelector((state) => state.lead);
  const { leaddetails } = useSelector((state) => state.lead);

  const [state, setState] = React.useState({
    leadId: "",
    leadContactFullName: "",
    meetingDescription: "",
    startTime: "",
    endTime: "",
    date: "",
    meetingAddress: "",
  });

  const renderLead = (leadItems) => {
    if (leadItems) {
      return leadItems.map((item) => (
        <MenuItem
          className={classes.MenuItem}
          key={item.id}
          value={item.id}
          data-my-value={item.contactFullName}
        >
          {item.contactFullName}
        </MenuItem>
      ));
    } else {
    }
  };

  const onSelectChange = (event) => {
    console.log("event", event);
    const { myValue } = event.currentTarget.dataset;
    console.log("Menu click event", myValue);

    setState({
      ...state,
      leadId: event.target.value,
      leadContactFullName: myValue,
    });
    console.log("state", state);
  };

  const handleDatetimeChange = (event) => {
    var myString = event.target.value;
    myString = myString.replace(/[^\d]/g, "");
    console.log("myString", myString);

    myString = Number(myString);
    setState({ ...state, [event.target.id]: myString });
    console.log("event.target.value", event.target.value);
  };

  const handleChange = (event) => {
    console.log("meet ", event.target.value);
    console.log("meet id ", [event.target.id]);
    console.log("meeti ", state);

    setState({ ...state, [event.target.id]: event.target.value });
  };

  const handleSubmit = () => {
    console.log("Handle submit");
    dispatch(create_meeting(state, activeOuid));
    history.push(`/${activeOuid}/meeting`);
  };

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let particularId = params.get("leadid");
  console.log("particularId", particularId);

  useEffect(() => {
    const fetchData = async (dispatch) => {
      if (particularId) {
        await dispatch(get_lead_details(particularId, activeOuid));
        setState({
          ...state,
          leadId: store.getState().lead.leaddetails.id,
          leadContactFullName:
            store.getState().lead.leaddetails.contactFullName,
        });
      } else {
        await dispatch(get_lead(activeOuid));
      }
    };
    fetchData(dispatch);
    console.log("leads", leads);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Create Meeting" />
      <div className="AppPage container--narrow mt">
        <p className="sub_text">Link Contact*</p>

        <form className="add_wrap" onSubmit={() => handleSubmit()}>
          {particularId ? (
            <TextField
              id="leadContactFullName"
              label="Full Name"
              fullWidth
              required
              disabled
              value={leaddetails && leaddetails.contactFullName}
              variant="outlined"
              className={classes.input}
              onChange={handleChange}
              inputProps={{
                style: {
                  textTransform: "capitalize",
                  color: "#131313",
                },
              }}
            />
          ) : (
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                Full Name
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="leadContactFullName"
                required
                // defaultValue={
                //   state.leadContactFullName && state.leadContactFullName
                // }
                value={state.leadId && state.leadId}
                onChange={onSelectChange}
                label="Full Name"
                className={classes.select}
              >
                {leads && renderLead(leads)}
              </Select>
            </FormControl>
          )}

          <h4 className="form_heading">Meeting Details</h4>
          <TextField
            id="meetingDescription"
            label="Description"
            fullWidth
            required
            variant="outlined"
            className={classes.input}
            onChange={handleChange}
          />

          <TextField
            id="date"
            label="Start Date"
            type="date"
            variant="outlined"
            required
            // defaultValue={new Date().toISOString().slice(0, 10)}
            inputProps={{
              // min: "2021-02-20T00:00",
              min: new Date().toISOString().slice(0, 10),
            }}
            // defaultValue="2017-05-24"
            className={classes.input}
            onChange={handleDatetimeChange}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            id="startTime"
            label="Start Time"
            type="time"
            variant="outlined"
            required
            // defaultValue={new Date().toISOString().slice(11, 16)}
            className={classes.input}
            onChange={handleDatetimeChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 60, // 1 min
            }}
          />
          <TextField
            id="endTime"
            required
            label="End Time"
            type="time"
            variant="outlined"
            // defaultValue="07:30"
            className={classes.input}
            onChange={handleDatetimeChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 60, // 1 min
            }}
          />
          <TextField
            id="meetingAddress"
            label="Address"
            required
            fullWidth
            variant="outlined"
            className={classes.input}
            onChange={handleChange}
          />
          {/* <button className="w-100 primary_btn" onClick={() => handleSubmit()}>
            Create Meeting
          </button> */}
          <div className="AppFloatingButtonWrapper">
            <Button
              type="submit"
              className="primary_btn"
              fullWidth
              variant="contained"
              color="primary"
              // onClick={() => handleSubmit()}
            >
              Create Meeting
            </Button>
          </div>
        </form>
      </div>
      <Footer />
      <CommonSnackBar />
    </div>
  );
}
