import {
  CREATE_MEETING_REQUESTED,
  CREATE_MEETING_SUCCESS,
  CREATE_MEETING_ERRORED,
  GET_MEETINGS_REQUESTED,
  GET_MEETINGS_SUCCESS,
  GET_MEETINGS_ERRORED,
  GET_MEETING_DETAILS_REQUESTED,
  GET_MEETING_DETAILS_SUCCESS,
  GET_MEETING_DETAILS_ERRORED,
  UPDATE_MEETING_REQUESTED,
  UPDATE_MEETING_SUCCESS,
  UPDATE_MEETING_ERRORED,
  DELETE_MEETING_REQUESTED,
  DELETE_MEETING_SUCCESS,
  DELETE_MEETING_ERRORED,
} from "../constant/action-types";

const defaultState = {
  showGlobalLoader: false,
  meetings: [],
  meetingDetails: {},
  error: {},
};

export function meeting(state = defaultState, { type, payload }) {
  switch (type) {
    case CREATE_MEETING_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case CREATE_MEETING_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case CREATE_MEETING_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_MEETINGS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_MEETINGS_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        meetings: payload,
      };

    case GET_MEETINGS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_MEETING_DETAILS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_MEETING_DETAILS_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        meetingDetails: payload,
      };

    case GET_MEETING_DETAILS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case UPDATE_MEETING_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case UPDATE_MEETING_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        meetings: payload,
      };

    case UPDATE_MEETING_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    case DELETE_MEETING_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case DELETE_MEETING_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case DELETE_MEETING_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    default:
      return state;
  }
}
