import {
  CREATE_LEAD_REQUESTED,
  CREATE_LEAD_SUCCESS,
  CREATE_LEAD_ERRORED,
  GET_LEADS_REQUESTED,
  GET_LEADS_SUCCESS,
  GET_LEADS_ERRORED,
  GET_LEAD_DETAILS_REQUESTED,
  GET_LEAD_DETAILS_SUCCESS,
  GET_LEAD_DETAILS_ERRORED,
  UPDATE_LEAD_REQUESTED,
  UPDATE_LEAD_SUCCESS,
  UPDATE_LEAD_ERRORED,
  DELETE_LEAD_REQUESTED,
  DELETE_LEAD_SUCCESS,
  DELETE_LEAD_ERRORED,
} from "../constant/action-types";

import axios from "axios";
import { showSnackbar } from "../../../../actions/action-snackbar";
import configureStore from "../../../../store/configureStore";

const store = configureStore();

export const create_lead_requested = () => ({
  type: CREATE_LEAD_REQUESTED,
});

export const create_lead_success = () => ({
  type: CREATE_LEAD_SUCCESS,
});

export const create_lead_errored = (error) => ({
  type: CREATE_LEAD_ERRORED,
  error,
});

export const get_lead_requested = () => ({
  type: GET_LEADS_REQUESTED,
});

export const get_lead_success = (payload) => ({
  type: GET_LEADS_SUCCESS,
  payload,
});

export const get_lead_errored = (error) => ({
  type: GET_LEADS_ERRORED,
  error,
});

export const get_lead_details_requested = () => ({
  type: GET_LEAD_DETAILS_REQUESTED,
});

export const get_lead_details_success = (payload) => ({
  type: GET_LEAD_DETAILS_SUCCESS,
  payload,
});

export const get_lead_details_errored = (error) => ({
  type: GET_LEAD_DETAILS_ERRORED,
  error,
});

export const update_lead_requested = () => ({
  type: UPDATE_LEAD_REQUESTED,
});

export const update_lead_success = (payload) => ({
  type: UPDATE_LEAD_SUCCESS,
  payload,
});

export const update_lead_errored = (error) => ({
  type: UPDATE_LEAD_ERRORED,
  error,
});

export const delete_lead_requested = () => ({
  type: DELETE_LEAD_REQUESTED,
});

export const delete_lead_success = (payload) => ({
  type: DELETE_LEAD_SUCCESS,
  payload,
});

export const delete_lead_errored = (error) => ({
  type: DELETE_LEAD_ERRORED,
  error,
});

export const create_lead = (payload, activeOuid) => async (dispatch) => {
  dispatch(create_lead_requested());
  console.log(`Create lead`);
  const { crmApiEndpoint, credentials } = store.getState().jwt.api;
  if (crmApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${crmApiEndpoint}/leads`;
    const method = "post";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        console.log("Lead created successfully");
        dispatch(showSnackbar("Lead created successfully", "success"));
        dispatch(create_lead_success());
        dispatch(get_lead(activeOuid));
      })
      .catch((err) => {
        dispatch(showSnackbar("Lead creation Failed", "error"));
        dispatch(create_lead_errored(err));
      });
  }
  return null;
};

export const get_lead = (activeOuid) => async (dispatch) => {
  dispatch(get_lead_requested());
  const { crmApiEndpoint, credentials } = store.getState().jwt.api;
  if (crmApiEndpoint && credentials) {

    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${crmApiEndpoint}/leads`;
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get lead Success", response.data);
        dispatch(get_lead_success(response.data.items));
      })
      .catch((err) => {
        console.log("Get lead Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get lead Failed", "error"));
        }
        dispatch(get_lead_errored(err));
      });
  }
  return null;
};

export const get_lead_details = (leadId, activeOuid) => async (dispatch) => {
  dispatch(get_lead_details_requested());
  const { crmApiEndpoint, credentials } = store.getState().jwt.api;
  if (crmApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${crmApiEndpoint}/leads/id/${leadId}`;
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get lead Success", response.data);
        dispatch(get_lead_details_success(response.data));
      })
      .catch((err) => {
        console.log("Get lead Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get lead Failed", "error"));
        }
        dispatch(get_lead_details_errored(err));
      });
  }
  return null;
};

export const update_lead = (payload, activeOuid, leadId) => async (dispatch) => {
  dispatch(update_lead_requested());
  console.log(`update_lead`);
  const { crmApiEndpoint, credentials } = store.getState().jwt.api;
  if (crmApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${crmApiEndpoint}/leads/id/${leadId}`;
    const { activeOuid, activeOuidChain } = store.getState().permission;
    const method = "put";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
        "x-ouidchain": activeOuidChain,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        console.log("Lead update Success");
        dispatch(showSnackbar("Lead Updated Successfully", "success"));
        dispatch(update_lead_success(response.data));
        dispatch(get_lead(activeOuid));
      })
      .catch((err) => {
        console.log("Lead update Failed");
        dispatch(showSnackbar("Lead update Failed", "error"));
        dispatch(update_lead_errored(err));
      });
  }
  return null;
};

export const delete_lead = (leadid,activeOuid) => async (dispatch) => {
  dispatch(delete_lead_requested());
  console.log(`delete_product`);
  const { crmApiEndpoint, credentials } = store.getState().jwt.api;
  if (crmApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${crmApiEndpoint}/leads/id/${leadid}`;
    const method = "delete";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        dispatch(showSnackbar("lead deleted successfully", "success"));
        dispatch(delete_lead_success());
        dispatch(get_lead(activeOuid));
      })
      .catch((err) => {
        if (err.response) {
          console.log(
            "Delete lead Failed :",
            JSON.stringify(err.response.data)
          );
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Delete failed", "error"));
        }
        dispatch(delete_lead_errored(err));
      });
  }
  return null;
};
