import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Header from "./Header";
import configureStore from "../../../store/configureStore";
import CommonSnackBar from "../../common/CommonSnackBar";
import { Button } from "@material-ui/core";
import "./App.css";
import { history } from "../../../reducers";
import contacts from "./images/contact-crm.svg";
import meeting from "./images/meeting-crm.svg";
import task from "./images/task-crm.svg";
import note from "./images/notes-crm.svg";
import docs from "./images/documents-crm.svg";
import crmbanner from "./images/crm-banner.svg";
import SearchField from "../../common/SearchField";
import Footer from "./Footer";

const store = configureStore();

export default function Home() {
  const dispatch = useDispatch();
  const { activeOuid } = store.getState().permission;
  const { details } = store.getState().myProfile;

  useEffect(() => {}, [dispatch]);

  return (
    <div className="">
      <Header />
      <div className="AppHomePage HrmAppPage container--narrow">
        {/* <div className="AppHeaderProfileWrapper">
          <div className="AppHeaderProfileDetails">
            <img src={avatar} />
            <div>
              <div className="AppHeaderProfileName">{details.data.name}</div>
              <div className="AppHeaderProfileDesignation">
                {details.data.username}
              </div>
            </div>
          </div>
        </div> */}
        <div className="homeBanner">
          <img src={crmbanner} />
        </div>
        <div className="AppHomeListWrapper">
          <div>
            <Link to={`/${activeOuid}/contacts`} className="AppHomeListItem">
              <img src={contacts} />
            </Link>
            <p>Contacts</p>
          </div>
          <div>
            <Link to={`/${activeOuid}/tasks`} className="AppHomeListItem">
              <img src={task} />
            </Link>
            <p>Tasks</p>
          </div>
          <div>
            <Link to={`/${activeOuid}/meeting`} className="AppHomeListItem">
              <img src={meeting} />
            </Link>
            <p>Meetings</p>
          </div>
          <div>
            <Link to={`/${activeOuid}/notes`} className="AppHomeListItem">
              <img src={note} />
            </Link>
            <p>Notes</p>
          </div>
          <div>
            <Link to={`/${activeOuid}/docs`} className="AppHomeListItem">
              <img src={docs} />
            </Link>
            <p>Docs</p>
          </div>
        </div>
        <div className="HrmAppBanner">{/* <img src={banner} /> */}</div>
      </div>
      <div className="AppFloatingButtonWrapper">
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => history.push(`/${activeOuid}/add-lead`)}
        >
          Add Lead
        </Button>
      </div>
      <Footer />
      <CommonSnackBar />
    </div>
  );
}
