import React, { useState } from "react";
import { Link } from "react-router-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Avatar, Drawer } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CallIcon from "@material-ui/icons/Call";
import EmailIcon from "@material-ui/icons/Email";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import configureStore from "../../../../store/configureStore";
import { useDispatch } from "react-redux";
import { update_lead, delete_lead } from "../actions/action-lead";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { history } from "../../../../reducers";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  avatar: {
    textTransform: "uppercase",
  },
}));

export default function LeadCard(props) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { activeOuid } = store.getState().permission;
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleLost = (leadId) => {
    var leadData = props.item;
    leadData.status = "failed";
    dispatch(update_lead(leadData, activeOuid, leadId));
  };

  const handleCustomer = (leadId) => {
    var leadData = props.item;
    leadData.status = "done";
    dispatch(update_lead(leadData, activeOuid, leadId));
  };

  const handleDelete = (leadId) => {
    dispatch(delete_lead(leadId, activeOuid));
  };

  return (
    <>
      <div className="profile_top mb">
        <Link
          to={`/${activeOuid}/contact-profile/${props.item.id}`}
          className="profile_left"
        >
          <div className="profile_img">
            <Avatar
              className={classes.avatar}
              style={{
                backgroundColor: "#214B87",
              }}
            >
              {props.item.contactFullName && props.item.contactFullName.charAt(0)}
            </Avatar>
          </div>
          <div className="profile_details">
            <h6 className="profile_name">{props.item.contactFullName}</h6>
            <div className="profile_company_name">{props.item.companyName}</div>
          </div>
        </Link>

        <div className="profile_right">
          <button className="option_btn" onClick={() => setIsDrawerOpen(true)}>
            <MoreVertIcon />
          </button>
          <Drawer
            className="edit_option_btn_drawer"
            anchor="bottom"
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
          >
            <div className="edit_option_head">options</div>
            <ul className="edit_option_tools_wrap">
              <li
                className="edit_option_tools_item"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = `tel:${props.item.contactPhoneNumber}`;
                }}
              >
                <div className="edit_option">
                  <CallIcon />
                  <span className="edit_option_tools_item_text">Call</span>
                </div>                
              </li>
              <li
                className="edit_option_tools_item"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = `mailto:${props.item.contactEmail}`;
                }}
              >
                <div className="edit_option">
                  <EmailIcon />
                  <span className="edit_option_tools_item_text">Email</span>
                </div>  
              </li>
              <li
                className="edit_option_tools_item"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    `https://api.whatsapp.com/send?phone=${props.item.contactPhoneNumber}&text=Hello...`,
                    "_blank"
                  );
                }}
              >
                <div className="edit_option">
                  <WhatsAppIcon />
                  <span className="edit_option_tools_item_text">WApp</span>
                </div>                
              </li>
              {props.showDelete && (
                <li
                  className="edit_option_tools_item"
                  onClick={() => handleDelete(props.item.id)}
                >
                  <div className="edit_option">
                    <DeleteIcon />
                    <span className="edit_option_tools_item_text">Delete</span>
                  </div>
                </li>
              )}
              <li
                className="edit_option_tools_item"
                onClick={() => handleLost(props.item.id)}
              >
                <div className="edit_option">
                  <PersonAddDisabledIcon />
                  <span className="edit_option_tools_item_text">Lost</span>
                </div>
              </li>
              <li
                className="edit_option_tools_item"
                onClick={() => handleCustomer(props.item.id)}
              >
                <div className="edit_option">
                  <VerifiedUserIcon />
                  <span className="edit_option_tools_item_text">Customer</span>
                </div>
              </li>
              <li
                className="edit_option_tools_item"
                onClick={() => setIsDrawerOpen(false)}
              >
                <div className="edit_option">
                  <EditIcon
                    onClick={() =>
                      history.push(`/${activeOuid}/edit-lead/${props.item.id}`)
                    }
                  />
                  <span className="edit_option_tools_item_text">Edit</span>
                </div>
              </li>
            </ul>
          </Drawer>
        </div>
      </div>
    </>
  );
}

LeadCard.propTypes = {
  item: PropTypes.object.isRequired,
  showDelete: PropTypes.bool,
};
