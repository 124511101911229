import {
  CREATE_NOTE_REQUESTED,
  CREATE_NOTE_SUCCESS,
  CREATE_NOTE_ERRORED,
  GET_NOTES_REQUESTED,
  GET_NOTES_SUCCESS,
  GET_NOTES_ERRORED,
  GET_NOTE_DETAILS_REQUESTED,
  GET_NOTE_DETAILS_SUCCESS,
  GET_NOTE_DETAILS_ERRORED,
  UPDATE_NOTE_REQUESTED,
  UPDATE_NOTE_SUCCESS,
  UPDATE_NOTE_ERRORED,
  DELETE_NOTE_REQUESTED,
  DELETE_NOTE_SUCCESS,
  DELETE_NOTE_ERRORED,
} from "../constant/action-types";

const defaultState = {
  showGlobalLoader: false,
  notes: [],
  noteDetails: {},
  error: {},
};

export function note(state = defaultState, { type, payload }) {
  switch (type) {
    case CREATE_NOTE_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case CREATE_NOTE_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case CREATE_NOTE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_NOTES_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_NOTES_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        notes: payload,
      };

    case GET_NOTES_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_NOTE_DETAILS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_NOTE_DETAILS_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        noteDetails: payload,
      };

    case GET_NOTE_DETAILS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case UPDATE_NOTE_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case UPDATE_NOTE_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        notes: payload,
      };

    case UPDATE_NOTE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case DELETE_NOTE_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case DELETE_NOTE_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case DELETE_NOTE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    default:
      return state;
  }
}
