import React, { useEffect } from "react";
import HeaderInner from "./HeaderInner";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import configureStore from "../../../store/configureStore";
import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux";
import {
  get_document_details,
  update_document,
} from "./actions/action-document";
import DocumentUploader, { delete_file } from "./common/DocumentUploader";
import { history } from "../../..//reducers";
import fileIcon from "./images/file.png";
import CloseIcon from "@material-ui/icons/Close";
import Loader from "../../common/Loader";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
  },
  input: {
    marginBottom: 10,
    background: "#fff",
    width: "100%",
  },
  formControl: {
    marginBottom: 10,
    background: "#fff",
    width: "100%",
  },
}));

export default function EditDocs() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeOuid, activeOuidChain } = store.getState().permission;
  // const { documents, showGlobalLoader } = store.getState().document;
  const { documentDetails, showGlobalLoader } = useSelector(
    (state) => state.document
  );

  const [state, setState] = React.useState({
    id: "",
    leadId: "",
    leadContactFullName: "",
    documentURL: "",
    documentKey: "",
    description: "",
  });

  const handleUploadChange = async (documentUrl) => {
    console.log("handleUploadChange", documentUrl);
    var dUrl = new URL(documentUrl);
    var dKey = dUrl.pathname;
    console.log("documentUrl", documentUrl);
    console.log("dKey", dKey);

    setState({
      ...state,
      documentURL: documentUrl,
      documentKey: dKey,
    });
    console.log("state", state);
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.id]: event.target.value });
  };

  const handleSubmit = () => {
    console.log("Handle submit");
    dispatch(update_document(state, activeOuid, state.id));
    history.push(`/${activeOuid}/docs`);
  };

  let dKeyId;
  dKeyId = state.documentKey;
  dKeyId = dKeyId.slice(1);
  console.log("dKeyId", dKeyId);

  const handleDelete = (event) => {
    event.preventDefault();
    console.log("Handle delete");
    dispatch(delete_file(dKeyId, activeOuid));
    setState({
      ...state,
      documentURL: "",
      documentKey: "",
    });
  };

  useEffect(() => {
    let docsId;
    docsId = window.location.pathname.split("/");
    docsId = docsId[3];
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_document_details(docsId, activeOuid));

      console.log(store.getState().document.documentdetails);

      setState({
        ...state,
        id: store.getState().document.documentDetails.id,
        leadId: store.getState().document.documentDetails.leadId,
        leadContactFullName:
          store.getState().document.documentDetails.leadContactFullName,
        documentURL: store.getState().document.documentDetails.documentURL,
        documentKey: store.getState().document.documentDetails.documentKey,
        description: store.getState().document.documentDetails.description,
      });
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Edit Docs" />
      <div className="HrmAppPage container--narrow mt">
        {showGlobalLoader ? (
          <Loader />
        ) : (
          <>
            <p className="sub_text">Link Contact*</p>

            <form className="add_wrap" onSubmit={handleSubmit}>
              <div className="image_input_wrap">
                {state.documentURL && (
                  <>
                    <img className="file_input_img_preview" src={fileIcon} />
                    <button className="img_delete_btn" onClick={handleDelete}>
                      <CloseIcon />
                    </button>
                  </>
                )}
                {state.documentURL === "" && (
                  <DocumentUploader
                    activeOuid={activeOuid}
                    activeOuidChain={activeOuidChain}
                    defaultState={state.upload}
                    handleUploadChange={handleUploadChange}
                  />
                )}
              </div>

              <TextField
                id="leadContactFullName"
                label="Full Name"
                fullWidth
                disabled
                required
                variant="outlined"
                defaultValue={state.leadContactFullName}
                value={state.leadContactFullName}
                className={classes.input}
                onChange={handleChange}
                inputProps={{
                  style: {
                    textTransform: "capitalize",
                    color: "#131313",
                  },
                }}
              />
              <h4 className="form_heading">File Details </h4>
              <TextField
                id="description"
                label="Description"
                fullWidth
                required
                variant="outlined"
                defaultValue={state.description}
                value={state.description}
                className={classes.input}
                onChange={handleChange}
              />
              <div className="AppFloatingButtonWrapper t-c">
                <button className="w-100  primary_btn" type="submit">
                  Update
                </button>
              </div>
            </form>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
}
