import React, { useEffect } from "react";
import HeaderInner from "./HeaderInner";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import configureStore from "../../../store/configureStore";
import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux";
import { get_note_details, update_note } from "./actions/action-notes";
import Loader from "../../common/Loader";
import { history } from "../../../reducers";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
  },
  input: {
    marginBottom: 10,
    background: "#fff",
    width: "100%",
  },
  formControl: {
    marginBottom: 10,
    background: "#fff",
    width: "100%",
  },
}));

export default function EditNotes() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeOuid, activeOuidChain } = store.getState().permission;
  const { noteDetails, showGlobalLoader } = useSelector((state) => state.note);

  const [state, setState] = React.useState({
    id: "",
    leadId: "",
    leadContactFullName: "",
    noteContent: "",
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.id]: event.target.value });
  };

  const handleSubmit = () => {
    console.log("Handle submit", state);
    dispatch(update_note(state, activeOuid, state.id));
    history.push(`/${activeOuid}/notes`);
  };

  useEffect(() => {
    let noteId;
    noteId = window.location.pathname.split("/");
    noteId = noteId[3];
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_note_details(noteId, activeOuid));

      console.log(store.getState().note.noteDetails);

      setState({
        ...state,
        id: store.getState().note.noteDetails.id,
        leadId: store.getState().note.noteDetails.leadId,
        leadContactFullName:
          store.getState().note.noteDetails.leadContactFullName,
        noteContent: store.getState().note.noteDetails.noteContent,
      });
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Edit Notes" />
      <div className="HrmAppPage container--narrow mt">
        {showGlobalLoader ? (
          <Loader />
        ) : (
          <>
            <p className="sub_text">Link Contact*</p>
            <form className="add_wrap" onSubmit={() => handleSubmit()}>
              <TextField
                id="contactFullName"
                label="Full Name"
                fullWidth
                disabled
                required
                variant="outlined"
                defaultValue={state.leadContactFullName}
                value={state.leadContactFullName}
                className={classes.input}
                onChange={handleChange}
                inputProps={{
                  style: {
                    textTransform: "capitalize",
                    color: "#131313",
                  },
                }}
              />
              <h4 className="form_heading">Take Down Notes </h4>
              <TextField
                id="noteContent"
                label="Description"
                fullWidth
                required
                variant="outlined"
                defaultValue={state.leadContactFullName}
                value={state.noteContent}
                className={classes.input}
                onChange={handleChange}
              />

              <div className="AppFloatingButtonWrapper t-c">
                <button type="submit" className="w-100 primary_btn">
                  Update
                </button>
              </div>
            </form>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
}
