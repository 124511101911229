import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import configureStore from "../../../store/configureStore";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import Home from "./Home";
import AddLead from "./AddLead";
import AddMeeting from "./AddMeeting";
import AddTask from "./AddTask";
import EditMeeting from "./EditMeeting";
import EditTask from "./EditTask";
import AddNotes from "./AddNotes";
import EditNotes from "./EditNotes";
import Profile from "./Profile";
import Contacts from "./Contacts";
import Schedule from "./Schedule";
import Notes from "./Notes";
import NotesDetails from "./NotesDetails";
import Docs from "./Docs";
import DocsDetails from "./DocsDetails";
import AddDocs from "./AddDocs";
import EditDocs from "./EditDocs";
import Meetings from "./Meetings";
import Tasks from "./Tasks";
import EditLead from "./EditLead";
import TaskDetails from "./TaskDetails";
import MeetingDetails from "./MeetingDetails";

const store = configureStore();
export default function CrmApp() {
  const dispatch = useDispatch();
  const activeOuid = store.getState().permission;

  useEffect(() => {
    console.log("inuseEffect");
  }, [dispatch]);

  const { permissions } = store.getState().permission;
  return (
    <div className="EcomApp">
      <Switch>
        <Route exact path="/" render={(props) => <Home />} />
        <Route exact path="/:id/add-lead" render={(props) => <AddLead />} />
        <Route
          exact
          path="/:id/edit-lead/:id"
          render={(props) => <EditLead />}
        />
        <Route
          exact
          path="/:id/add-meeting"
          render={(props) => <AddMeeting />}
        />
        <Route exact path="/:id/add-task" render={(props) => <AddTask />} />
        <Route
          exact
          path="/:id/edit-meeting/:id"
          render={(props) => <EditMeeting />}
        />
        <Route
          exact
          path="/:id/edit-task/:id"
          render={(props) => <EditTask />}
        />
        <Route exact path="/:id/add-notes" render={(props) => <AddNotes />} />
        <Route
          exact
          path="/:id/edit-notes/:id"
          render={(props) => <EditNotes />}
        />
        <Route exact path="/:id/add-docs" render={(props) => <AddDocs />} />
        <Route
          exact
          path="/:id/edit-docs/:id"
          render={(props) => <EditDocs />}
        />
        <Route
          exact
          path="/:id/contact-profile/:id"
          render={(props) => <Profile />}
        />
        <Route exact path="/:id/contacts" render={(props) => <Contacts />} />
        <Route exact path="/:id/schedule" render={(props) => <Schedule />} />
        <Route exact path="/:id/meeting" render={(props) => <Meetings />} />
        <Route exact path="/:id/tasks" render={(props) => <Tasks />} />

        <Route
          exact
          path="/:id/schedule/:id"
          render={(props) => <Schedule />}
        />
        <Route exact path="/:id/notes" render={(props) => <Notes />} />
        <Route
          exact
          path="/:id/notes-details/:id"
          render={(props) => <NotesDetails />}
        />
        <Route
          exact
          path="/:id/task-details/:id"
          render={(props) => <TaskDetails />}
        />
        <Route exact path="/:id/docs" render={(props) => <Docs />} />
        <Route
          exact
          path="/:id/docs-details/:id"
          render={(props) => <DocsDetails />}
        />
        <Route
          exact
          path="/:id/meeting-details/:id"
          render={(props) => <MeetingDetails />}
        />
      </Switch>
    </div>
  );
}
