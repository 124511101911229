import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import configureStore from "../../../store/configureStore";
import HeaderInner from "./HeaderInner";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import profimg from "./images/pro.png";
import { Avatar, Drawer } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import SwipeableViews from "react-swipeable-views";
import Loader from "../../common/Loader";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { clearAllListeners } from "@reduxjs/toolkit";
import deleteicon from "./images/delete.svg";
import lost from "./images/lost.svg";
import lead from "./images/lead.svg";
import editicon from "./images/edit.svg";
import call from "./images/call.svg";
import message from "./images/message.svg";
import email from "./images/email.svg";
import whatsapp from "./images/whatsapp.svg";
import meeting from "./images/meeting.svg";
import task from "./images/task.svg";
import notes from "./images/notes.svg";
import Footer from "./Footer";
import {
  delete_lead,
  get_lead_details,
  update_lead,
} from "./actions/action-lead";
import { get_meeting } from "./actions/action-meeting";
import { get_note } from "./actions/action-notes";
import NotesCard from "./common/NotesCard";
import { get_task } from "./actions/action-task";
import DocsCard from "./common/DocsCard";
import { Button } from "@material-ui/core";
import { history } from "../../../reducers";
import AddIcon from "@material-ui/icons/Add";
import TaskCard from "./common/TaskCard";
import MeetingCard from "./common/MeetingCard";
import EditIcon from "@material-ui/icons/Edit";
import { get_document_with_query } from "./actions/action-document";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    margin: "0 auto",
  },
  attendanceButtonWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  tabs: {
    background: "#fff",
    boxShadow: "none",
  },
  tab: {
    borderBottom: "1.98502px solid #4B4DED",
  },
  tabpanel: {
    padding: "0px",
  },
  tabHead: {
    boxShadow: "none",
    borderTop: "1px solid #eee",
  },
  avatar: {
    textTransform: "uppercase",
  },
}));

const store = configureStore();

export default function Profile(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { activeOuid } = store.getState().permission;
  const [isDrawerOpen, setIsDrawerOPen] = useState(false);

  const [value, setValue] = React.useState(0);

  const { leaddetails, showGlobalLoader } = useSelector((state) => state.lead);
  const { meetings } = useSelector((state) => state.meeting);
  const { notes } = useSelector((state) => state.note);
  const { tasks } = useSelector((state) => state.task);
  const { documents } = useSelector((state) => state.document);

  const handleLost = (leadId) => {
    console.log("Props", leaddetails);
    var leadData = leaddetails;
    leadData.status = "failed";
    console.log("leadData", leadId);
    dispatch(update_lead(leadData, activeOuid, leadId));
  };

  const handleCustomer = (leadId) => {
    var leadData = props.item;
    leadData.status = "done";
    console.log("leadData", leadId);
    dispatch(update_lead(leadData, activeOuid, leadId));
  };

  function renderContactList(leaddetails) {
    return (
      <div>
        <ul className="profile_tools_wrap">
          <li className="profile_tools_item">
            <Link to={`/${activeOuid}/schedule/${leaddetails.id}`}>
              <img src={meeting} />
              <span className="profile_tools_item_name">Meeting</span>
            </Link>
          </li>
          <li className="profile_tools_item">
            <Link to={`/${activeOuid}/task`}>
              <img src={task} />
              <span className="profile_tools_item_name">Task</span>
            </Link>
          </li>
          <li className="profile_tools_item">
            <Link to={`/${activeOuid}/schedule`}>
              <img src={notes} />
              <span className="profile_tools_item_name">notes</span>
            </Link>
          </li>
          <li className="profile_tools_item">
            <Link to={`/${activeOuid}/schedule`}>
              <img src={editicon} />

              <span className="profile_tools_item_name">Edit</span>
            </Link>
          </li>
        </ul>
      </div>
    );
  }

  function renderMeetingList(meetings) {
    console.log("meeting", meetings);
    return meetings.map((item) => <MeetingCard key={item.id} item={item} />);
  }

  function renderNotesList(notes) {
    console.log("notes", notes);
    if (notes) {
      return notes.map((item) => <NotesCard key={item.id} item={item} />);
    } else {
      <div>No notes found</div>;
    }
  }

  function renderTaskList(tasks) {
    console.log("task", tasks);
    return tasks.map((item) => <TaskCard key={item.id} item={item} />);
  }

  function renderDocsList(documents) {
    console.log("doc", documents);
    return documents.map((item) => <DocsCard key={item.id} item={item} />);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  useEffect(() => {
    let leadId = window.location.pathname.split("/");
    leadId = leadId[3];
    console.log("leadId", leadId);
    const fetchData = async (dispatch) => {
      await dispatch(get_lead_details(leadId, activeOuid));
      await dispatch(get_meeting(`?leadId=${leadId}`, activeOuid));
      await dispatch(get_note(`?leadId=${leadId}`, activeOuid));
      await dispatch(get_task(`?leadId=${leadId}`, activeOuid));
      await dispatch(get_document_with_query(`?leadId=${leadId}`, activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);

  console.log("meetings", meetings);

  return (
    <div>
      <HeaderInner pageTitle="Profile " />
      <div className="HrmAppPage container--narrow mt">
        <div className="profile_top">
          <div className="profile_left">
            <div className="profile_img">
              <Avatar
                className={classes.avatar}
                style={{
                  backgroundColor: "#214B87",
                }}
              >
                {leaddetails.contactFullName &&
                  leaddetails.contactFullName.charAt(0)}
              </Avatar>
            </div>
            <div className="profile_details">
              <h6 className="profile_name">{leaddetails.contactFullName}</h6>
              <div className="profile_company_name">
                {leaddetails.companyName}
              </div>
            </div>
          </div>
          <div className="profile_right">
            <button
              className="option_btn"
              onClick={() => setIsDrawerOPen(true)}
            >
              <MoreVertIcon />
            </button>
            <Drawer
              className="edit_option_btn_drawer"
              anchor="bottom"
              open={isDrawerOpen}
              onClose={() => setIsDrawerOPen(false)}
            >
              <div className="edit_option_head">options</div>
              <ul className="edit_option_tools_wrap">
                {leaddetails.status === "open" && (
                  <>
                    <li
                      className="edit_option_tools_item"
                      onClick={() => {
                        setIsDrawerOPen(false);
                        handleLost(leaddetails.id);
                      }}
                    >
                      <div className="edit_option">
                        <img src={lost} />
                        <span className="edit_option_tools_item_text">Lost</span>
                      </div>
                    </li>
                    <li
                      className="edit_option_tools_item"
                      onClick={() => {
                        setIsDrawerOPen(false);
                        handleCustomer(leaddetails.id);
                      }}
                    >
                      <div className="edit_option">
                        <img src={lead} />
                        <span className="edit_option_tools_item_text">
                          Customer
                        </span>
                      </div>
                    </li>
                  </>
                )}

                {leaddetails.status === "done" && (
                  <>
                    <li
                      className="edit_option_tools_item"
                      onClick={() => {
                        setIsDrawerOPen(false);
                        handleLost(leaddetails.id);
                      }}
                    >
                      <div className="edit_option">
                        <img src={lost} />
                        <span className="edit_option_tools_item_text">Lost</span>
                      </div>
                    </li>
                  </>
                )}

                {leaddetails.status === "failed" && (
                  <>
                    <li
                      className="edit_option_tools_item"
                      onClick={() => {
                        setIsDrawerOPen(false);
                        handleCustomer(leaddetails.id);
                      }}
                    >
                      <div className="edit_option">
                        <img src={lead} />
                        <span className="edit_option_tools_item_text">
                          Customer
                        </span>
                      </div>
                    </li>
                  </>
                )}

                <li
                  className="edit_option_tools_item"
                  onClick={() => {
                    setIsDrawerOPen(false);
                  }}
                >
                  <div className="edit_option">
                    <EditIcon
                      onClick={() =>
                        history.push(`/${activeOuid}/edit-lead/${leaddetails.id}`)
                      }
                    />
                    <span className="edit_option_tools_item_text">Edit</span>
                  </div>
                </li>
              </ul>
            </Drawer>
          </div>
        </div>
        <div>
          <AppBar position="static" color="default" className={classes.tabHead}>
            <Tabs
              className={classes.tabs}
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab className={classes.tab} label="Meetings" {...a11yProps(0)} />
              <Tab className={classes.tab} label="Tasks" {...a11yProps(1)} />
              <Tab className={classes.tab} label="Notes" {...a11yProps(2)} />
              <Tab className={classes.tab} label="Doc" {...a11yProps(3)} />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel
              value={value}
              index={0}
              dir={theme.direction}
              className={classes.tabpanel}
            >
              <div className="crm_profile_tab_panel_wrap">
                <div className="crm_profile_tab_panel_float_btn mt mb">
                  <button
                    className="circle_btn bg_green"
                    color="primary"
                    onClick={() =>
                      history.push(
                        `/${activeOuid}/add-meeting?leadid=${leaddetails.id}`
                      )
                    }
                  >
                    <AddIcon />
                  </button>
                </div>
                {meetings
                  ? renderMeetingList(meetings)
                  : "No meetings found..."}
              </div>
            </TabPanel>
            <TabPanel
              value={value}
              index={1}
              dir={theme.direction}
              className={classes.tabpanel}
            >
              <div className="mt mb">
                <button
                  className="circle_btn bg_green"
                  color="primary"
                  onClick={() =>
                    history.push(
                      `/${activeOuid}/add-task?leadid=${leaddetails.id}`
                    )
                  }
                >
                  <AddIcon />
                </button>
              </div>
              {tasks ? renderTaskList(tasks) : "No task found..."}
            </TabPanel>
            <TabPanel
              value={value}
              index={2}
              dir={theme.direction}
              className={classes.tabpanel}
            >
              <div className="mt mb">
                <button
                  className="circle_btn bg_green"
                  color="primary"
                  onClick={() =>
                    history.push(
                      `/${activeOuid}/add-notes?leadid=${leaddetails.id}`
                    )
                  }
                >
                  <AddIcon />
                </button>
              </div>
              {notes ? renderNotesList(notes) : "No notes found..."}
            </TabPanel>
            <TabPanel
              value={value}
              index={3}
              dir={theme.direction}
              className={classes.tabpanel}
            >
              <div className="mt mb">
                <button
                  className="circle_btn bg_green"
                  color="primary"
                  onClick={() =>
                    history.push(
                      `/${activeOuid}/add-docs?leadid=${leaddetails.id}`
                    )
                  }
                >
                  <AddIcon />
                </button>
              </div>
              {documents ? renderDocsList(documents) : "No docs found..."}
              {/* {docs && renderDocsList(docs)} */}
            </TabPanel>
          </SwipeableViews>
        </div>
      </div>
      <Footer />
    </div>
  );
}
