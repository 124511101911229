import React, { useState } from "react";
import { Link } from "react-router-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Avatar, Drawer } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { history } from "../../../../reducers";
import { useDispatch } from "react-redux";
import configureStore from "../../../../store/configureStore";
import { delete_meeting } from "../actions/action-meeting";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  avatar: {
    textTransform: "uppercase",
  },
}));

export default function MeetingCard(props) {
  const [isDrawerOpen, setIsDrawerOPen] = useState(false);
  const { activeOuid } = store.getState().permission;
  const dispatch = useDispatch();
  const classes = useStyles();

  function dateFormat(value) {
    var date = value.toString();
    return date.replace(/(\d{4})(\d{2})(\d{2})/g, "$3-$2-$1");
  }

  const convertFrom24To12Format = (time24) => {
    const [sHours, minutes] = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
    const period = +sHours < 12 ? "AM" : "PM";
    const hours = +sHours % 12 || 12;

    return `${hours}:${minutes} ${period}`;
  };

  function addStr(str, index, stringToAdd) {
    if (str.length === 3) {
      str = "0" + str;
    }
    return (
      str.substring(0, index) + stringToAdd + str.substring(index, str.length)
    );
  }

  const handleDetele = () => {
    console.log("props.item.username delete", props.item.id);
    dispatch(delete_meeting(activeOuid, props.item.id));
  };

  return (
    <div className="schedule_card mb">
      {props.profile && (
        <div className="profile_top">
          <Link
            to={`/${activeOuid}/contact-profile/${props.item.leadId}`}
            className="profile_left"
          >
            <div className="profile_img">
              <Avatar
                className={classes.avatar}
                style={{
                  backgroundColor: "#214B87",
                }}
              >
                {props.item.leadContactFullName &&
                  props.item.leadContactFullName.charAt(0)}
              </Avatar>
            </div>
            <div className="profile_details">
              <h6 className="profile_name">{props.item.leadContactFullName}</h6>
              {/* <div className="profile_company_name">shadi.com</div> */}
            </div>
          </Link>

          <div className="profile_right"></div>
        </div>
      )}

      <Link
        to={`/${activeOuid}/meeting-details/${props.item.id}`}
        className="card_desc mt"
      >
        {props.item.meetingDescription}
      </Link>

      <div className="schedule_time_wrap">
        <div className="schedule_time_range">
          <span className="schedule_card_time">
            {convertFrom24To12Format(
              addStr(props.item.startTime.toString(), 2, ":")
            )}
          </span>
          <span className="schedule_to">to</span>
          <span className="schedule_card_time">
            {convertFrom24To12Format(
              addStr(props.item.endTime.toString(), 2, ":")
            )}
          </span>
        </div>
        {/* <div className="schedule_check_icon">
          <CheckBoxOutlinedIcon />
          <CheckBoxIcon/>
        </div> */}
      </div>
      <div className="schedule_card_bottom">
        <div className="schedule_card_bottom_left">
          <span className="schedule_card_date">
            {props.item.date && dateFormat(props.item.date, "####-##-##")}
          </span>
        </div>
        <div className="schedule_card_bottom_right">
          <button
            className="card_option_btn"
            onClick={() => setIsDrawerOPen(true)}
          >
            <MoreVertIcon />
          </button>
          <Drawer
            className="edit_option_btn_drawer"
            anchor="bottom"
            open={isDrawerOpen}
            onClose={() => setIsDrawerOPen(false)}
          >
            <div className="edit_option_head">options</div>
            <ul className="edit_option_tools_wrap">
              <li
                className="edit_option_tools_item"
                onClick={() => setIsDrawerOPen(false)}
              >
                <div className="edit_option">
                  <EditIcon
                    onClick={() =>
                      history.push(`/${activeOuid}/edit-meeting/${props.item.id}`)
                    }
                  />
                  <span className="edit_option_tools_item_text">Edit</span>
                </div>
              </li>
              <li
                className="edit_option_tools_item"
                onClick={() => setIsDrawerOPen(false)}
              >
                <div className="edit_option">
                  <DeleteIcon onClick={() => handleDetele()} />
                  <span className="edit_option_tools_item_text">Delete</span>
                </div>
              </li>
            </ul>
          </Drawer>
        </div>
      </div>
    </div>
  );
}
