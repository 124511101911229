import {
  CREATE_DOCUMENT_REQUESTED,
  CREATE_DOCUMENT_SUCCESS,
  CREATE_DOCUMENT_ERRORED,
  GET_ALL_DOCUMENTS_REQUESTED,
  GET_ALL_DOCUMENTS_SUCCESS,
  GET_ALL_DOCUMENTS_ERRORED,
  GET_DOCUMENT_DETAILS_REQUESTED,
  GET_DOCUMENT_DETAILS_SUCCESS,
  GET_DOCUMENT_DETAILS_ERRORED,
  DOCUMENT_UPLOAD_REQUESTED,
  DOCUMENT_UPLOAD_SUCCESS,
  DOCUMENT_UPLOAD_ERRORED,
  UPDATE_DOCUMENT_REQUESTED,
  UPDATE_DOCUMENT_SUCCESS,
  UPDATE_DOCUMENT_ERRORED,
  DELETE_DOCUMENT_REQUESTED,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_ERRORED,
} from "../constant/action-types";
import axios from "axios";
import { showSnackbar } from "../../../../actions/action-snackbar";

import configureStore from "../../../../store/configureStore";

const store = configureStore();

export const document_upload_requested = () => ({
  type: DOCUMENT_UPLOAD_REQUESTED,
});

export const document_upload_success = (payload) => ({
  type: DOCUMENT_UPLOAD_SUCCESS,
  payload,
});

export const document_upload_errored = (error) => ({
  type: DOCUMENT_UPLOAD_ERRORED,
  error,
});

export const create_document_requested = () => ({
  type: CREATE_DOCUMENT_REQUESTED,
});

export const create_document_success = () => ({
  type: CREATE_DOCUMENT_SUCCESS,
});

export const create_document_errored = (error) => ({
  type: CREATE_DOCUMENT_ERRORED,
  error,
});

export const delete_document_requested = () => ({
  type: DELETE_DOCUMENT_REQUESTED,
});

export const delete_document_success = () => ({
  type: DELETE_DOCUMENT_SUCCESS,
});

export const delete_document_errored = (error) => ({
  type: DELETE_DOCUMENT_ERRORED,
  error,
});

export const get_document_details_requested = () => ({
  type: GET_DOCUMENT_DETAILS_REQUESTED,
});

export const get_document_details_success = (payload) => ({
  type: GET_DOCUMENT_DETAILS_SUCCESS,
  payload,
});

export const get_document_details_errored = (error) => ({
  type: GET_DOCUMENT_DETAILS_ERRORED,
  error,
});

export const update_document_requested = () => ({
  type: UPDATE_DOCUMENT_REQUESTED,
});

export const update_document_success = (payload) => ({
  type: UPDATE_DOCUMENT_SUCCESS,
  payload,
});

export const update_document_errored = (error) => ({
  type: UPDATE_DOCUMENT_ERRORED,
  error,
});

export const create_document = (payload) => async (dispatch) => {
  dispatch(create_document_requested());
  const path = window.location.pathname.split("/");
  const activeOuid = path[1];
  const { activeOuidChain } = store.getState().permission;
  console.log(`create_document`);
  const { crmApiEndpoint, credentials } = store.getState().jwt.api;
  if (crmApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    const url = `${crmApiEndpoint}/documents`;
    // console.log(`jwt : ${jwt}`);
    // console.log(`url : ${url}`);
    const method = "post";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
        "x-ouidchain": activeOuidChain,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        // console.log("response : ",JSON.stringify(response));
        dispatch(showSnackbar("Document created Successfully", "success"));
        dispatch(create_document_success());
        dispatch(get_document(activeOuid));
      })
      .catch((err) => {
        console.log("Document creation Failed");
        // console.error('ErrorLog : ', JSON.stringify(err));
        dispatch(showSnackbar("Document creation Failed", "error"));
        dispatch(create_document_errored(err));
      });
  }
  return null;
};

export const update_document =
  (payload, activeOuid, documentId) => async (dispatch) => {
    dispatch(update_document_requested());
    console.log(`update_document`);

    const { crmApiEndpoint, credentials } = store.getState().jwt.api;
    if (crmApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${crmApiEndpoint}/documents/id/${documentId}`;
      const { activeOuid, activeOuidChain } = store.getState().permission;
      const method = "put";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("document Updation Success");
          dispatch(showSnackbar("document Updated Successfully", "success"));
          dispatch(update_document_success());
          dispatch(get_document(activeOuid));
        })
        .catch((err) => {
          console.log("document updation Failed");
          dispatch(showSnackbar("document updation Failed", "error"));
          dispatch(update_document_errored(err));
        });
    }
    return null;
  };

export const delete_document = (docId, activeOuid) => async (dispatch) => {
  console.log("in delete_document()");
  dispatch(delete_document_requested());
  const { activeOuidChain } = store.getState().permission;
  console.log(`delete_document`);
  const { crmApiEndpoint, credentials } = store.getState().jwt.api;
  if (crmApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    const url = `${crmApiEndpoint}/documents/id/${docId}`;
    const method = "delete";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
        "x-ouidchain": activeOuidChain,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        dispatch(showSnackbar("Document Deleted Successfully", "success"));
        dispatch(delete_document_success());
        dispatch(get_document(activeOuid));
      })
      .catch((err) => {
        console.log("Document Deletion Failed");
        dispatch(showSnackbar("Document Deletion Failed", "error"));
        dispatch(delete_document_errored(err));
      });
  }
  return null;
};

export const get_all_documents_by_ouid_requested = () => ({
  type: GET_ALL_DOCUMENTS_REQUESTED,
});

export const get_all_documents_by_ouid_success = (payload) => ({
  type: GET_ALL_DOCUMENTS_SUCCESS,
  payload,
});

export const get_all_documents_by_ouid_errored = (error) => ({
  type: GET_ALL_DOCUMENTS_ERRORED,
  error,
});

export const get_document = (activeOuid) => async (dispatch) => {
  dispatch(get_all_documents_by_ouid_requested());
  const { crmApiEndpoint, credentials } = store.getState().jwt.api;
  if (crmApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${crmApiEndpoint}/documents`;
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then(async (response) => {
        console.log("Get All Documents Successfully", response);
        dispatch(get_all_documents_by_ouid_success(response.data));
      })
      .catch((err) => {
        console.log("Get All Documents Failed");
        console.error("ErrorLog : ", JSON.stringify(err));
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get All Documents Failed", "error"));
        }
        dispatch(get_all_documents_by_ouid_errored(err));
      });
  }
  return null;
};

export const get_document_details =
  (documentId, activeOuid) => async (dispatch) => {
    dispatch(get_document_details_requested());
    const { crmApiEndpoint, credentials } = store.getState().jwt.api;
    console.log(`crmApiEndpoint`, crmApiEndpoint);
    console.log(`credentials`, credentials);

    if (crmApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${crmApiEndpoint}/documents/id/${documentId}`;

      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get document Success", response.data);
          dispatch(get_document_details_success(response.data));
        })
        .catch((err) => {
          console.log("Get document Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get document Failed", "error"));
          }
          dispatch(get_document_details_errored(err));
        });
    }
    return null;
  };

export const get_document_with_query =
  (query, activeOuid) => async (dispatch) => {
    dispatch(get_all_documents_by_ouid_requested());
    const { crmApiEndpoint, credentials } = store.getState().jwt.api;
    if (crmApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${crmApiEndpoint}/documents${query}`;
      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
        },
      };
      return axios[method](url, axiosHeader)
        .then(async (response) => {
          console.log("Get All Documents Successfully", response.data);
          dispatch(get_all_documents_by_ouid_success(response.data));
        })
        .catch((err) => {
          console.log("Get All Documents Failed");
          // console.error('ErrorLog : ', JSON.stringify(err));
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get All Documents Failed", "error"));
          }
          dispatch(get_all_documents_by_ouid_errored(err));
        });
    }
    return null;
  };

const get_document_signed_url = async (activeOuid, key) => {
  console.log(`get_document_signed_url_requested->key`, key);
  const { crmApiEndpoint, credentials } = store.getState().jwt.api;
  if (crmApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${crmApiEndpoint}/uploads?filename=${key}`;
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("get_document_signed_url response", response);
        return response.data.getURL;
      })
      .catch((err) => {
        console.log("get_document_signed_url Failed");
      });
  }
  return null;
};

export const get_all_documents_by_leadid_requested = () => ({
  type: GET_ALL_DOCUMENTS_REQUESTED,
});

export const get_all_documents_by_leadid_success = (payload) => ({
  type: GET_ALL_DOCUMENTS_SUCCESS,
  payload,
});

export const get_all_documents_by_leadid_errored = (error) => ({
  type: GET_ALL_DOCUMENTS_ERRORED,
  error,
});

export const get_all_documents_by_leadid =
  (activeOuid, leadid) => async (dispatch) => {
    dispatch(get_all_documents_by_leadid_requested());
    console.log(`get_all_documents_by_workid`);
    const { crmApiEndpoint, credentials } = store.getState().jwt.api;
    if (crmApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${crmApiEndpoint}/documents?leadId=${leadid}`;
      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
        },
      };
      return axios[method](url, axiosHeader)
        .then(async (response) => {
          console.log("Get All Documents Successful");
          console.log("Get All Documents Successful response", response);
          //loop and update reponse.data with signedURL
          for (let index = 0; index < response.data.items.length; index++) {
            const element = response.data.items[index];
            var signedUrl = await get_document_signed_url(
              activeOuid,
              element.data.key
            );
            console.log("*********signedURL", signedUrl);
            element["SignedUrl"] = signedUrl;
          }
          console.log("*********get_all_documents_by_workid_success");

          dispatch(get_all_documents_by_leadid_success(response.data));
        })
        .catch((err) => {
          console.log("Get All Documents Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get All Documents Failed", "error"));
          }
          dispatch(get_all_documents_by_leadid_errored(err));
        });
    }
    return null;
  };
