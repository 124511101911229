import {
  CREATE_TASK_REQUESTED,
  CREATE_TASK_SUCCESS,
  CREATE_TASK_ERRORED,
  GET_TASKS_REQUESTED,
  GET_TASKS_SUCCESS,
  GET_TASKS_ERRORED,
  GET_TASK_DETAILS_REQUESTED,
  GET_TASK_DETAILS_SUCCESS,
  GET_TASK_DETAILS_ERRORED,
  UPDATE_TASK_REQUESTED,
  UPDATE_TASK_SUCCESS,
  UPDATE_TASK_ERRORED,
  DELETE_TASK_REQUESTED,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_ERRORED,
} from "../constant/action-types";

const defaultState = {
  showGlobalLoader: false,
  tasks: [],
  taskDetails: {},
  error: {},
};

export function task(state = defaultState, { type, payload }) {
  switch (type) {
    case CREATE_TASK_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case CREATE_TASK_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case CREATE_TASK_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_TASKS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_TASKS_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        tasks: payload,
      };

    case GET_TASKS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_TASK_DETAILS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_TASK_DETAILS_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        taskDetails: payload,
      };

    case GET_TASK_DETAILS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case UPDATE_TASK_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case UPDATE_TASK_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        tasks: payload,
      };

    case UPDATE_TASK_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    case DELETE_TASK_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case DELETE_TASK_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case DELETE_TASK_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    default:
      return state;
  }
}
