export const RESET_STATE = "RESET_STATE";

export const CREATE_LEAD_REQUESTED = "CREATE_LEAD_REQUESTED";
export const CREATE_LEAD_SUCCESS = "CREATE_LEAD_SUCCESS";
export const CREATE_LEAD_ERRORED = "CREATE_LEAD_ERRORED";
export const GET_LEADS_REQUESTED = "GET_LEADS_REQUESTED";
export const GET_LEADS_SUCCESS = "GET_LEADS_SUCCESS";
export const GET_LEADS_ERRORED = "GET_LEADS_ERRORED";
export const GET_LEAD_DETAILS_REQUESTED = "GET_LEAD_DETAILS_REQUESTED";
export const GET_LEAD_DETAILS_SUCCESS = "GET_LEAD_DETAILS_SUCCESS";
export const GET_LEAD_DETAILS_ERRORED = "GET_LEAD_DETAILS_ERRORED";
export const UPDATE_LEAD_REQUESTED = "UPDATE_LEAD_REQUESTED";
export const UPDATE_LEAD_SUCCESS = "UPDATE_LEAD_SUCCESS";
export const UPDATE_LEAD_ERRORED = "UPDATE_LEAD_ERRORED";
export const DELETE_LEAD_REQUESTED = "DELETE_LEAD_REQUESTED";
export const DELETE_LEAD_SUCCESS = "DELETE_LEAD_SUCCESS";
export const DELETE_LEAD_ERRORED = "DELETE_LEAD_ERRORED";

export const CREATE_TASK_REQUESTED = "CREATE_TASK_REQUESTED";
export const CREATE_TASK_SUCCESS = "CREATE_TASK_SUCCESS";
export const CREATE_TASK_ERRORED = "CREATE_TASKS_ERRORED";
export const GET_TASKS_REQUESTED = "GET_TASKS_REQUESTED";
export const GET_TASKS_SUCCESS = "GET_TASKS_SUCCESS";
export const GET_TASKS_ERRORED = "GET_TASK_ERRORED";
export const GET_TASK_DETAILS_REQUESTED = "GET_TASK_DETAILS_REQUESTED";
export const GET_TASK_DETAILS_SUCCESS = "GET_TASK_DETAILS_SUCCESS";
export const GET_TASK_DETAILS_ERRORED = "GET_TASK_DETAILS_ERRORED";
export const UPDATE_TASK_REQUESTED = "UPDATE_TASK_REQUESTED";
export const UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS";
export const UPDATE_TASK_ERRORED = "UPDATE_TASK_ERRORED";
export const DELETE_TASK_REQUESTED = "DELETE_TASK_REQUESTED";
export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS";
export const DELETE_TASK_ERRORED = "DELETE_TASK_ERRORED";

export const CREATE_MEETING_REQUESTED = "CREATE_MEETING_REQUESTED";
export const CREATE_MEETING_SUCCESS = "CREATE_MEETING_SUCCESS";
export const CREATE_MEETING_ERRORED = "CREATE_MEETING_ERRORED";
export const GET_MEETINGS_REQUESTED = "GET_MEETINGS_REQUESTED";
export const GET_MEETINGS_SUCCESS = "GET_MEETINGS_SUCCESS";
export const GET_MEETINGS_ERRORED = "GET_MEETINGS_ERRORED";
export const GET_MEETING_DETAILS_REQUESTED = "GET_MEETING_DETAILS_REQUESTED";
export const GET_MEETING_DETAILS_SUCCESS = "GET_MEETING_DETAILS_SUCCESS";
export const GET_MEETING_DETAILS_ERRORED = "GET_MEETING_DETAILS_ERRORED";
export const UPDATE_MEETING_REQUESTED = "UPDATE_MEETING_REQUESTED";
export const UPDATE_MEETING_SUCCESS = "UPDATE_MEETING_SUCCESS";
export const UPDATE_MEETING_ERRORED = "UPDATE_MEETING_ERRORED";
export const DELETE_MEETING_REQUESTED = "DELETE_MEETING_REQUESTED";
export const DELETE_MEETING_SUCCESS = "DELETE_MEETING_SUCCESS";
export const DELETE_MEETING_ERRORED = "DELETE_MEETING_ERRORED";

export const CREATE_NOTE_REQUESTED = "CREATE_NOTE_REQUESTED";
export const CREATE_NOTE_SUCCESS = "CREATE_NOTE_SUCCESS";
export const CREATE_NOTE_ERRORED = "CREATE_NOTE_ERRORED";
export const GET_NOTES_REQUESTED = "GET_NOTES_REQUESTED";
export const GET_NOTES_SUCCESS = "GET_NOTES_SUCCESS";
export const GET_NOTES_ERRORED = "GET_NOTES_ERRORED";
export const GET_NOTE_DETAILS_REQUESTED = "GET_NOTE_DETAILS_REQUESTED";
export const GET_NOTE_DETAILS_SUCCESS = "GET_NOTE_DETAILS_SUCCESS";
export const GET_NOTE_DETAILS_ERRORED = "GET_NOTE_DETAILS_ERRORED";
export const UPDATE_NOTE_REQUESTED = "UPDATE_NOTE_REQUESTED";
export const UPDATE_NOTE_SUCCESS = "UPDATE_NOTE_SUCCESS";
export const UPDATE_NOTE_ERRORED = "UPDATE_NOTE_ERRORED";
export const DELETE_NOTE_REQUESTED = "DELETE_NOTE_REQUESTED";
export const DELETE_NOTE_SUCCESS = "DELETE_NOTE_SUCCESS";
export const DELETE_NOTE_ERRORED = "DELETE_NOTE_ERRORED";

export const CREATE_DOCUMENT_REQUESTED = "CREATE_DOCUMENT_REQUESTED";
export const CREATE_DOCUMENT_SUCCESS = "CREATE_DOCUMENT_SUCCESS";
export const CREATE_DOCUMENT_ERRORED = "CREATE_DOCUMENT_ERRORED";
export const GET_ALL_DOCUMENTS_REQUESTED = "GET_ALL_DOCUMENT_REQUESTED";
export const GET_ALL_DOCUMENTS_SUCCESS = "GET_ALL_DOCUMENT_SUCCESS";
export const GET_ALL_DOCUMENTS_ERRORED = "GET_ALL_DOCUMENT_ERRORED";
export const GET_DOCUMENT_DETAILS_REQUESTED = "GET_DOCUMENT_DETAILS_REQUESTED";
export const GET_DOCUMENT_DETAILS_SUCCESS = "GET_DOCUMENT_DETAILS_SUCCESS";
export const GET_DOCUMENT_DETAILS_ERRORED = "GET_DOCUMENT_DETAILS_ERRORED";
export const DOCUMENT_UPLOAD_REQUESTED = "DOCUMENT_UPLOAD_REQUESTED";
export const DOCUMENT_UPLOAD_SUCCESS = "DOCUMENT_UPLOAD_SUCCESS";
export const DOCUMENT_UPLOAD_ERRORED = "DOCUMENT_UPLOAD_ERRORED";
export const DELETE_DOCUMENT_REQUESTED = "DELETE_DOCUMENT_REQUESTED";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_ERRORED = "DELETE_DOCUMENT_ERRORED";
export const UPDATE_DOCUMENT_REQUESTED = "UPDATE_DOCUMENT_REQUESTED";
export const UPDATE_DOCUMENT_SUCCESS = "UPDATE_DOCUMENT_SUCCESS";
export const UPDATE_DOCUMENT_ERRORED = "UPDATE_DOCUMENT_ERRORED";
