import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/Loader";
import ScheduleCard from "./common/ScheduleCard";
import HeaderInner from "./HeaderInner";
import configureStore from "../../../store/configureStore";
import { get_meeting } from "./actions/action-meeting";
import { history } from "../../../reducers";
import Footer from "./Footer";

const store = configureStore();

// function renderScheduleList(contact) {
//   // console.log("render leave list*********", contact);
//   return (
//     <>
//       <ScheduleCard />
//     </>
//   );
// }

export default function Schedule() {
  const { activeOuid } = store.getState().permission;
  const dispatch = useDispatch();
  const { meetings, showGlobalLoader } = useSelector((state) => state.meeting);

  function renderScheduleList(meetings) {
    return meetings.map((item) => <ScheduleCard key={item.id} item={item} />);
  }

  useEffect(() => {
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_meeting("",activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Schedule " />
      <div className="HrmAppPage container--narrow mt">
        {showGlobalLoader ? <Loader /> : renderScheduleList(meetings)}
        <div
          className="AppFloatingButtonWrapper"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <button
            className="w-50  primary_btn"
            onClick={() => history.push(`/${activeOuid}/add-meeting`)}
          >
            Meeting
          </button>
          <button
            className="w-50  secondary_btn"
            onClick={() => history.push(`/${activeOuid}/add-task`)}
          >
            Task
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
}
