import React, { useState } from "react";
import { Link } from "react-router-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import profimg from "../images/pro.png";
import { Drawer } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

export default function ScheduleCard(props) {
  const [isDrawerOpen, setIsDrawerOPen] = useState(false);

  return (
    <div className="schedule_card">
      <div className="profile_top">
        <Link to="/" className="profile_left">
          <div className="profile_img">
            <img src={profimg} />
          </div>
          <div className="profile_details">
            <h6 className="profile_name">{props.item.leadContactFullName}</h6>
            <div className="profile_company_name">shadi.com</div>
          </div>
        </Link>

        <div className="profile_right">
          <span className="schedule_card_date">{props.item.date}</span>
        </div>
      </div>
      <p className="card_desc mt">{props.item.meetingDescription}</p>
      <div className="schedule_card_bottom">
        <div className="schedule_card_bottom_left">
          <span className="schedule_card_time">{props.item.endTime}</span>
        </div>
        <div className="schedule_card_bottom_right">
          <span className="schedule_type">Meet</span>
          <button className="option_btn" onClick={() => setIsDrawerOPen(true)}>
            <MoreVertIcon />
          </button>
          <Drawer
            className="edit_option_btn_drawer"
            anchor="bottom"
            open={isDrawerOpen}
            onClose={() => setIsDrawerOPen(false)}
          >
            <div className="edit_option_head">options</div>
            <ul className="edit_option_tools_wrap">
              <li
                className="edit_option_tools_item"
                onClick={() => setIsDrawerOPen(false)}
              >
                <div className="edit_option">
                  <DeleteIcon />
                  <span className="edit_option_tools_item_text">Delete</span>
                </div>
              </li>
              <li
                className="edit_option_tools_item"
                onClick={() => setIsDrawerOPen(false)}
              >
                <div className="edit_option">
                  <DeleteIcon />
                  <span className="edit_option_tools_item_text">Delete</span>
                </div>
              </li>
              <li
                className="edit_option_tools_item"
                onClick={() => setIsDrawerOPen(false)}
              >
                <div className="edit_option">
                  <DeleteIcon />
                  <span className="edit_option_tools_item_text">Delete</span>
                </div>
              </li>
              <li
                className="edit_option_tools_item"
                onClick={() => setIsDrawerOPen(false)}
              >
                <div className="edit_option">
                  <DeleteIcon />
                  <span className="edit_option_tools_item_text">Delete</span>
                </div>
              </li>
              <li
                className="edit_option_tools_item"
                onClick={() => setIsDrawerOPen(false)}
              >
                <div className="edit_option">
                  <DeleteIcon />
                  <span className="edit_option_tools_item_text">Delete</span>
                </div>
              </li>
            </ul>
          </Drawer>
        </div>
      </div>
    </div>
  );
}
