import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderInner from "./HeaderInner";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Button, TextField } from "@material-ui/core";
import configureStore from "../../../store/configureStore";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { create_document } from "./actions/action-document";
import Footer from "./Footer";
import { get_lead, get_lead_details } from "./actions/action-lead";
import DocumentUploader, { delete_file } from "./common/DocumentUploader";
import { history } from "../../..//reducers";
import fileIcon from "./images/file.png";
import CloseIcon from "@material-ui/icons/Close";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
  },
  input: {
    marginBottom: 10,
    background: "#fff",
    width: "100%",
  },
  formControl: {
    marginBottom: 10,
    background: "#fff",
    width: "100%",
  },
}));

export default function AddDocs() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeOuid, activeOuidChain } = store.getState().permission;
  const { documentUrl, showGlobalLoader } = store.getState().document;
  console.log("link", documentUrl);
  const { leads } = useSelector((state) => state.lead);
  const { leaddetails } = useSelector((state) => state.lead);

  const [state, setState] = React.useState({
    leadId: "",
    leadContactFullName: "",
    documentURL: "",
    documentKey: "",
    description: "",
  });

  const renderLead = (leadItems) => {
    if (leadItems) {
      return leadItems.map((item) => (
        <MenuItem
          className={classes.MenuItem}
          key={item.id}
          value={item.id}
          data-my-value={item.contactFullName}
        >
          {item.contactFullName}
        </MenuItem>
      ));
    }
  };

  const onSelectChange = (event) => {
    console.log("event", event);
    const { myValue } = event.currentTarget.dataset;
    console.log("Menu click event", myValue);

    setState({
      ...state,
      leadId: event.target.value,
      leadContactFullName: myValue,
    });
    console.log("state", state);
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.id]: event.target.value });
    console.log("state", state);
  };

  const handleUploadChange = async (documentUrl) => {
    console.log("handleUploadChange", documentUrl);
    var dUrl = new URL(documentUrl);
    var dKey = dUrl.pathname;
    console.log("documentUrl", documentUrl);
    console.log("dKey", dKey);

    setState({
      ...state,
      documentURL: documentUrl,
      documentKey: dKey,
    });
    console.log("state", state);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Handle submit");
    dispatch(create_document(state, activeOuid));
    history.push(`/${activeOuid}/docs`);
  };

  let dKeyId;
  dKeyId = state.documentKey;
  dKeyId = dKeyId.slice(1);
  console.log("dKeyId", dKeyId);

  const handleDelete = (event) => {
    event.preventDefault();
    console.log("Handle delete");
    dispatch(delete_file(dKeyId, activeOuid));
    setState({
      ...state,
      documentURL: "",
      documentKey: "",
    });
  };

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let particularId = params.get("leadid");
  console.log("particularId", particularId);

  useEffect(() => {
    const fetchData = async (dispatch) => {
      if (particularId) {
        await dispatch(get_lead_details(particularId, activeOuid));
        setState({
          ...state,
          leadId: store.getState().lead.leaddetails.id,
          leadContactFullName:
            store.getState().lead.leaddetails.contactFullName,
        });
      } else {
        await dispatch(get_lead(activeOuid));
      }
    };
    fetchData(dispatch);
    console.log("leads", leads);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Add Files" />
      <div className="CrmAppPage container--narrow mt">
        <form className="add_wrap mt" onSubmit={handleSubmit}>
          <div className="image_input_wrap">
            {state.documentURL && (
              <>
                <img className="file_input_img_preview" src={fileIcon} />
                <button className="img_delete_btn" onClick={handleDelete}>
                  <CloseIcon />
                </button>
              </>
            )}
            {state.documentURL === "" && (
              <DocumentUploader
                activeOuid={activeOuid}
                activeOuidChain={activeOuidChain}
                defaultState={state.upload}
                handleUploadChange={handleUploadChange}
              />
            )}
          </div>

          {particularId ? (
            <TextField
              id="leadContactFullName"
              label="Full Name"
              fullWidth
              required
              disabled
              value={leaddetails && leaddetails.contactFullName}
              variant="outlined"
              className={classes.input}
              onChange={handleChange}
              inputProps={{
                style: {
                  textTransform: "capitalize",
                  color: "#131313",
                },
              }}
            />
          ) : (
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                Full Name
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="leadContactFullName"
                required
                value={state.leadId && state.leadId}
                onChange={onSelectChange}
                label="Full Name"
                className={classes.select}
              >
                {leads && renderLead(leads)}
              </Select>
            </FormControl>
          )}
          <h4 className="form_heading">File Details </h4>
          <TextField
            id="description"
            label="Description"
            fullWidth
            variant="outlined"
            className={classes.input}
            onChange={handleChange}
            required
          />

          <div className="AppFloatingButtonWrapper t-c">
            <button className="w-100  primary_btn" type="submit">
              Create Docs
            </button>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
}
