import {
  CREATE_NOTE_REQUESTED,
  CREATE_NOTE_SUCCESS,
  CREATE_NOTE_ERRORED,
  GET_NOTES_REQUESTED,
  GET_NOTES_SUCCESS,
  GET_NOTES_ERRORED,
  GET_NOTE_DETAILS_REQUESTED,
  GET_NOTE_DETAILS_SUCCESS,
  GET_NOTE_DETAILS_ERRORED,
  UPDATE_NOTE_REQUESTED,
  UPDATE_NOTE_SUCCESS,
  UPDATE_NOTE_ERRORED,
  DELETE_NOTE_REQUESTED,
  DELETE_NOTE_SUCCESS,
  DELETE_NOTE_ERRORED,
} from "../constant/action-types";

import axios from "axios";

import { showSnackbar } from "../../../../actions/action-snackbar";

import configureStore from "../../../../store/configureStore";

const store = configureStore();

export const create_note_requested = () => ({
  type: CREATE_NOTE_REQUESTED,
});

export const create_note_success = () => ({
  type: CREATE_NOTE_SUCCESS,
});

export const create_note_errored = (error) => ({
  type: CREATE_NOTE_ERRORED,
  error,
});

export const get_note_requested = () => ({
  type: GET_NOTES_REQUESTED,
});

export const get_note_success = (payload) => ({
  type: GET_NOTES_SUCCESS,
  payload,
});

export const get_note_errored = (error) => ({
  type: GET_NOTES_ERRORED,
  error,
});

export const get_note_details_requested = () => ({
  type: GET_NOTE_DETAILS_REQUESTED,
});

export const get_note_details_success = (payload) => ({
  type: GET_NOTE_DETAILS_SUCCESS,
  payload,
});

export const get_note_details_errored = (error) => ({
  type: GET_NOTE_DETAILS_ERRORED,
  error,
});

export const update_note_requested = () => ({
  type: UPDATE_NOTE_REQUESTED,
});

export const update_note_success = (payload) => ({
  type: UPDATE_NOTE_SUCCESS,
  payload,
});

export const update_note_errored = (error) => ({
  type: UPDATE_NOTE_ERRORED,
  error,
});

export const delete_note_requested = () => ({
  type: DELETE_NOTE_REQUESTED,
});

export const delete_note_success = (payload) => ({
  type: DELETE_NOTE_SUCCESS,
  payload,
});

export const delete_note_errored = (error) => ({
  type: DELETE_NOTE_ERRORED,
  error,
});

export const create_note = (payload, activeOuid) => async (dispatch) => {
  dispatch(create_note_requested());
  console.log(`Create note`);
  const { crmApiEndpoint, credentials } = store.getState().jwt.api;
  if (crmApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${crmApiEndpoint}/notes`;
    const method = "post";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        console.log("note created Successfully");
        dispatch(showSnackbar("note created Successfully", "success"));
        dispatch(create_note_success());
        dispatch(get_note("", activeOuid));
      })
      .catch((err) => {
        console.log("note creation Failed");
        // console.error('ErrorLog : ', JSON.stringify(err));
        dispatch(showSnackbar("note creation Failed", "error"));
        dispatch(create_note_errored(err));
      });
  }
  return null;
};

export const get_note = (query, activeOuid) => async (dispatch) => {
  dispatch(get_note_requested());
  const { crmApiEndpoint, credentials } = store.getState().jwt.api;
  if (crmApiEndpoint && credentials) {
    console.log(`hrmApiEndpoint`);

    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${crmApiEndpoint}/notes${query}`;
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get note Success", response.data);
        dispatch(get_note_success(response.data.items));
      })
      .catch((err) => {
        console.log("Get note Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get note Failed", "error"));
        }
        dispatch(get_note_errored(err));
      });
  }
  return null;
};

export const get_note_details = (noteId, activeOuid) => async (dispatch) => {
  dispatch(get_note_details_requested());
  const { crmApiEndpoint, credentials } = store.getState().jwt.api;
  console.log(`crmApiEndpoint`, crmApiEndpoint);
  console.log(`credentials`, credentials);

  if (crmApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${crmApiEndpoint}/notes/id/${noteId}`;

    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get note Success", response.data);
        dispatch(get_note_details_success(response.data));
      })
      .catch((err) => {
        console.log("Get note Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get note Failed", "error"));
        }
        dispatch(get_note_details_errored(err));
      });
  }
  return null;
};

export const update_note =
  (payload, activeOuid, noteId) => async (dispatch) => {
    dispatch(update_note_requested());
    console.log(`update_note`);

    const { crmApiEndpoint, credentials } = store.getState().jwt.api;
    if (crmApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${crmApiEndpoint}/notes/id/${noteId}`;
      const { activeOuid, activeOuidChain } = store.getState().permission;
      const method = "put";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("note Updation Success");
          dispatch(showSnackbar("Note Updated Successfully", "success"));
          dispatch(update_note_success());
          dispatch(get_note("", activeOuid));
        })
        .catch((err) => {
          console.log("note updation Failed");
          dispatch(showSnackbar("Note updation Failed", "error"));
          dispatch(update_note_errored(err));
        });
    }
    return null;
  };

export const delete_note = (activeOuid, noteId) => async (dispatch) => {
  dispatch(delete_note_requested());
  console.log(`delete_note`);
  const { crmApiEndpoint, credentials } = store.getState().jwt.api;
  if (crmApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${crmApiEndpoint}/notes/id/${noteId}`;
    const method = "delete";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Delete note Success");
        dispatch(showSnackbar("note deleted successfully", "success"));
        dispatch(delete_note_success());
        dispatch(get_note("", activeOuid));
      })
      .catch((err) => {
        if (err.response) {
          console.log(
            "Delete note Failed :",
            JSON.stringify(err.response.data)
          );
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Delete failed", "error"));
        }
        dispatch(delete_note_errored(err));
      });
  }
  return null;
};
