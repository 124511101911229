import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/Loader";
import { get_meeting } from "./actions/action-meeting";
import Footer from "./Footer";
import HeaderInner from "./HeaderInner";
import configureStore from "../../../store/configureStore";
import { history } from "../../../reducers";
import MeetingCard from "./common/MeetingCard";
import CommonSnackBar from "../../common/CommonSnackBar";

const store = configureStore();

export default function Meetings() {
  const { activeOuid } = store.getState().permission;
  const dispatch = useDispatch();
  const { meetings, showGlobalLoader } = useSelector((state) => state.meeting);

  function renderMeetingList(meetings) {
    if (meetings) {
      return meetings.map((item) => (
        <MeetingCard key={item.id} item={item} profile={true} />
      ));
    } else {
      return <>No Meetings found</>;
    }
  }

  useEffect(() => {
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_meeting("", activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Meetings " onClick={() => {history.push("/")}}/>
      <div className="AppPage container--narrow mt pb">
        {showGlobalLoader ? <Loader /> : renderMeetingList(meetings)}
        <div className="AppFloatingButtonWrapper">
          <button
            className="w-100  secondary_btn"
            onClick={() => history.push(`/${activeOuid}/add-meeting`)}
          >
            Add Meeting
          </button>
        </div>
      </div>
      <Footer />
      <CommonSnackBar />
    </div>
  );
}
