import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import configureStore from "../../../store/configureStore";
import HeaderInner from "./HeaderInner";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import profimg from "./images/pro.png";
import { Button, Drawer } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import SwipeableViews from "react-swipeable-views";
import Loader from "../../common/Loader";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { clearAllListeners } from "@reduxjs/toolkit";
import LeadCard from "./common/LeadCard";
import { get_lead } from "./actions/action-lead";
import Footer from "./Footer";
import { history } from "../../../reducers";
import CommonSnackBar from "../../common/CommonSnackBar";

// const leads = [
//   {
//     id: "0490d29b-df98-43cf-8e25-72f1aba025c5",
//     companyName: "Shvvaadicc",
//     contactFullName: "Job Tvvhocccmas",
//     contactEmail: "job.thvvomccas@shaadi.com",
//     companyWebsite: "www.sccvvchaadi.com",
//     handler: "bijuspace@gmail.com",
//     createdAt: "2022-06-22T22:59:58Z",
//     createdBy: "bijuspace@gmail.com",
//     status: "done",
//     ouid: "edwin",
//     entity: "lead",
//   },
// ];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    margin: "0 auto",
  },
  attendanceButtonWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  tabs: {
    background: "#fff",
    boxShadow: "none",
  },
  tab: {
    borderBottom: "1.98502px solid #4B4DED",
  },
  tabpanel: {
    padding: "0px",
  },
  tabHead: {
    boxShadow: "none",
    borderTop: "1px solid #eee",
  },
}));

const store = configureStore();

function renderLeadsList(leads) {
  return leads
    .filter((item) => item.status === "open")
    .map((item) => <LeadCard key={item.id} item={item} />);
}

function renderCustomerList(leads) {
  return leads
    .filter((item) => item.status === "done")
    .map((item) => <LeadCard key={item.id} item={item} />);
}

function renderLostList(leads) {
  return leads
    .filter((item) => item.status === "failed")
    .map((item) => <LeadCard key={item.id} item={item} showDelete={true} />);
}

export default function Contacts() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { activeOuid } = store.getState().permission;
  const { leads, showGlobalLoader } = useSelector((state) => state.lead);

  const [value, setValue] = React.useState(0);

  useEffect(() => {
    const fetchData = async (dispatch) => {
      await dispatch(get_lead(activeOuid));
    };

    //  const unsubscribe = navigation.addListener("focus", () => {
    //    fetchData(dispatch);
    //    //Put your Data loading function here instead of my loadData()
    //  });
    fetchData(dispatch);
  }, [dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  return (
    <div>
      <HeaderInner pageTitle="Contacts"  onClick={() => {history.push("/")}} />
      <div className="AppPage container--narrow mt">
        <AppBar position="static" color="default" className={classes.tabHead}>
          <Tabs
            className={classes.tabs}
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab className={classes.tab} label="Leads" {...a11yProps(0)} />
            <Tab className={classes.tab} label="Customers" {...a11yProps(1)} />
            <Tab className={classes.tab} label="Lost" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel
            value={value}
            index={0}
            dir={theme.direction}
            className={classes.tabpanel}
          >
            {showGlobalLoader ? (
              <Loader />
            ) : (
              <>{leads ? renderLeadsList(leads) : "No leads found..."}</>
            )}
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            dir={theme.direction}
            className={classes.tabpanel}
          >
            {showGlobalLoader ? (
              <Loader />
            ) : (
              <>{leads ? renderCustomerList(leads) : "No contact found..."}</>
            )}
          </TabPanel>
          <TabPanel
            value={value}
            index={2}
            dir={theme.direction}
            className={classes.tabpanel}
          >
            {showGlobalLoader ? (
              <Loader />
            ) : (
              <>{leads ? renderLostList(leads) : "No contact found..."}</>
            )}
          </TabPanel>
        </SwipeableViews>
      </div>
      <div className="AppFloatingButtonWrapper">
        <button
          className="w-100  secondary_btn"
          onClick={() => history.push(`/${activeOuid}/add-lead`)}
        >
          Add Lead
        </button>
      </div>
      <CommonSnackBar />

      <Footer />
    </div>
  );
}