import {
  CREATE_TASK_REQUESTED,
  CREATE_TASK_SUCCESS,
  CREATE_TASK_ERRORED,
  GET_TASKS_REQUESTED,
  GET_TASKS_SUCCESS,
  GET_TASKS_ERRORED,
  GET_TASK_DETAILS_REQUESTED,
  GET_TASK_DETAILS_SUCCESS,
  GET_TASK_DETAILS_ERRORED,
  UPDATE_TASK_REQUESTED,
  UPDATE_TASK_SUCCESS,
  UPDATE_TASK_ERRORED,
  DELETE_TASK_REQUESTED,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_ERRORED,
} from "../constant/action-types";

import axios from "axios";

import { showSnackbar } from "../../../../actions/action-snackbar";

import configureStore from "../../../../store/configureStore";

const store = configureStore();

export const create_task_requested = () => ({
  type: CREATE_TASK_REQUESTED,
});

export const create_task_success = () => ({
  type: CREATE_TASK_SUCCESS,
});

export const create_task_errored = (error) => ({
  type: CREATE_TASK_ERRORED,
  error,
});

export const get_task_requested = () => ({
  type: GET_TASKS_REQUESTED,
});

export const get_task_success = (payload) => ({
  type: GET_TASKS_SUCCESS,
  payload,
});

export const get_task_errored = (error) => ({
  type: GET_TASKS_ERRORED,
  error,
});

export const get_task_details_requested = () => ({
  type: GET_TASK_DETAILS_REQUESTED,
});

export const get_task_details_success = (payload) => ({
  type: GET_TASK_DETAILS_SUCCESS,
  payload,
});

export const get_task_details_errored = (error) => ({
  type: GET_TASK_DETAILS_ERRORED,
  error,
});

export const update_task_requested = () => ({
  type: UPDATE_TASK_REQUESTED,
});

export const update_task_success = (payload) => ({
  type: UPDATE_TASK_SUCCESS,
  payload,
});

export const update_task_errored = (error) => ({
  type: UPDATE_TASK_ERRORED,
  error,
});
export const delete_task_requested = () => ({
  type: DELETE_TASK_REQUESTED,
});

export const delete_task_success = (payload) => ({
  type: DELETE_TASK_SUCCESS,
  payload,
});

export const delete_task_errored = (error) => ({
  type: DELETE_TASK_ERRORED,
  error,
});

export const create_task = (payload, activeOuid) => async (dispatch) => {
  dispatch(create_task_requested());
  console.log(`Create task`);
  const { crmApiEndpoint, credentials } = store.getState().jwt.api;
  if (crmApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${crmApiEndpoint}/tasks`;
    const method = "post";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        console.log("task created Successfully");
        dispatch(showSnackbar("task created Successfully", "success"));
        dispatch(create_task_success(response));
        dispatch(get_task("?status=open", activeOuid));
      })
      .catch((err) => {
        console.log("task creation Failed");
        // console.error('ErrorLog : ', JSON.stringify(err));
        dispatch(showSnackbar("task creation Failed", "error"));
        dispatch(create_task_errored(err));
      });
  }
  return null;
};

export const update_task =
  (payload, activeOuid, taskId) => async (dispatch) => {
    dispatch(update_task_requested());
    console.log(`update_task`);

    const { crmApiEndpoint, credentials } = store.getState().jwt.api;
    if (crmApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${crmApiEndpoint}/tasks/id/${taskId}`;
      const { activeOuid, activeOuidChain } = store.getState().permission;
      const method = "put";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("task Updation Success");
          dispatch(showSnackbar("task Updated Successfully", "success"));
          dispatch(update_task_success());
          dispatch(get_task("?status=open", activeOuid));
        })
        .catch((err) => {
          console.log("task updation Failed");
          dispatch(showSnackbar("task updation Failed", "error"));
          dispatch(update_task_errored(err));
        });
    }
    return null;
  };

export const get_task = (query, activeOuid) => async (dispatch) => {
  dispatch(get_task_requested());
  const { crmApiEndpoint, credentials } = store.getState().jwt.api;
  if (crmApiEndpoint && credentials) {
    console.log(`crmApiEndpoint`);

    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${crmApiEndpoint}/tasks${query}`;
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get task Success", response.data);
        dispatch(get_task_success(response.data.items));
      })
      .catch((err) => {
        console.log("Get task Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get task Failed", "error"));
        }
        dispatch(get_task_errored(err));
      });
  }
  return null;
};

export const get_task_details = (taskId, activeOuid) => async (dispatch) => {
  dispatch(get_task_details_requested());
  const { crmApiEndpoint, credentials } = store.getState().jwt.api;
  console.log(`crmApiEndpoint`, crmApiEndpoint);
  console.log(`credentials`, credentials);

  if (crmApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${crmApiEndpoint}/tasks/id/${taskId}`;

    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get task Success", response.data);
        dispatch(get_task_details_success(response.data));
      })
      .catch((err) => {
        console.log("Get task Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get task Failed", "error"));
        }
        dispatch(get_task_details_errored(err));
      });
  }
  return null;
};

export const delete_task = (activeOuid, taskId) => async (dispatch) => {
  dispatch(delete_task_requested());
  console.log(`delete_task`);
  const { crmApiEndpoint, credentials } = store.getState().jwt.api;
  if (crmApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${crmApiEndpoint}/tasks/id/${taskId}`;
    const method = "delete";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Delete task Success");
        dispatch(showSnackbar("task deleted successfully", "success"));
        dispatch(delete_task_success());
        dispatch(get_task("?status=open", activeOuid));
      })
      .catch((err) => {
        if (err.response) {
          console.log(
            "Delete task Failed :",
            JSON.stringify(err.response.data)
          );
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Delete task", "error"));
        }
        dispatch(delete_task_errored(err));
      });
  }
  return null;
};
