import React, { useEffect } from "react";
import HeaderInner from "./HeaderInner";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import configureStore from "../../../store/configureStore";
import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux";
import { get_task_details, update_task } from "./actions/action-task";
import Loader from "../../common/Loader";
import { history } from "../../..//reducers";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
  },
  input: {
    marginBottom: 10,
    background: "#fff",
    width: "100%",
  },
  formControl: {
    marginBottom: 10,
    background: "#fff",
    width: "100%",
  },
}));

const formattedDateTime = (dateTime) => {
  if (!dateTime) return "";
  const dateTimeStr = dateTime.toString().padStart(12, '0'); 
  return `${dateTimeStr.substring(0, 4)}-${dateTimeStr.substring(4, 6)}-${dateTimeStr.substring(6, 8)}T${dateTimeStr.substring(8, 10)}:${dateTimeStr.substring(10, 12)}`;
};


export default function EditTask() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeOuid, activeOuidChain } = store.getState().permission;
  const { taskdetails, showGlobalLoader } = useSelector((state) => state.task);

  const [state, setState] = React.useState({
    id: "",
    leadId: "",
    leadContactId: "",
    leadContactFullName: "",
    taskDetail: "",
    taskHandler: "",
    taskDateTime: "",
  });

  const onSelectChange = (event) => {
    setState({ ...state, lead: event.target.value });
  };

  const handleDatetimeChange = (event) => {
    var myString = event.target.value;
    myString = myString.replace(/[^\d]/g, "");
    myString = Number(myString);
    setState({ ...state, [event.target.id]: myString });
  }; 

  const handleChange = (event) => {
    setState({ ...state, [event.target.id]: event.target.value });
  };

  const handleSubmit = () => {
    dispatch(update_task(state, activeOuid, state.id));
    history.push(`/${activeOuid}/tasks`);
  };

  useEffect(() => {
    let taskId;
    taskId = window.location.pathname.split("/");
    taskId = taskId[3];
    const fetchData = async (dispatch) => {
      await dispatch(get_task_details(taskId, activeOuid));

      setState({
        ...state,
        id: store.getState().task.taskDetails.id,
        leadId: store.getState().task.taskDetails.leadId,
        leadContactId: store.getState().task.taskDetails.leadContactId,
        leadContactFullName:
          store.getState().task.taskDetails.leadContactFullName,
        taskDetail: store.getState().task.taskDetails.taskDetail,
        taskHandler: store.getState().task.taskDetails.taskHandler,
        taskDateTime: store.getState().task.taskDetails.taskDateTime,
      });    
    };
    
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Edit Task" />
      <div className="HrmAppPage container--narrow mt">
        {showGlobalLoader ? (
          <Loader />
        ) : (
          <>
            <p className="sub_text">Link Contact*</p>
            <form className="add_wrap" onSubmit={() => handleSubmit()}>
              <TextField
                id="leadContactFullName"
                label="Full Name"
                fullWidth
                variant="outlined"
                defaultValue={state.leadContactFullName}
                value={state.leadContactFullName}
                disabled
                required
                className={classes.input}
                onChange={handleChange}
                inputProps={{
                  style: {
                    textTransform: "capitalize",
                    color: "#131313",
                  },
                }}
              />
              <h4 className="form_heading">Task Details</h4>
              <TextField
                id="taskDetail"
                label="Description"
                fullWidth
                variant="outlined"
                required
                defaultValue={state.taskDetail}
                value={state.taskDetail}
                className={classes.input}
                onChange={handleChange}
              />
              <TextField
                id="taskDateTime"
                variant="outlined"
                className={classes.input}
                label="Date and time"
                type="datetime-local"
                required
                defaultValue={formattedDateTime(state.taskDateTime)}
                value={formattedDateTime(state.taskDateTime)}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleDatetimeChange}
              />
              <div className="AppFloatingButtonWrapper t-c">
                <button type="submit" className="w-100 primary_btn">
                  Update
                </button>
              </div>
            </form>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
}
