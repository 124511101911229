import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/Loader";
import DocsCard from "./common/DocsCard";
import HeaderInner from "./HeaderInner";
import configureStore from "../../../store/configureStore";
import { get_document } from "./actions/action-document";
import { history } from "../../../reducers";
import Footer from "./Footer";
import CommonSnackBar from "../../common/CommonSnackBar";

const store = configureStore();

export default function Docs() {
  const { activeOuid } = store.getState().permission;
  const dispatch = useDispatch();
  const { documents, showGlobalLoader } = useSelector(
    (state) => state.document
  );

  function renderDocsList(documents) {
    console.log("documents", documents);
    if (documents) {
      return documents.map((item) => <DocsCard key={item.id} item={item} />);
    } else {
      return <>No documents found</>;
    }
  }

  useEffect(() => {
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_document(activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Docs " onClick={() => {history.push("/")}}/>
      <div className="HrmAppPage container--narrow mt pb">
        {showGlobalLoader ? <Loader /> : renderDocsList(documents)}
        <div className="AppFloatingButtonWrapper">
          <button
            className="w-100  secondary_btn"
            onClick={() => history.push(`/${activeOuid}/add-docs`)}
          >
            Add File
          </button>
        </div>
      </div>
      <Footer />
      <CommonSnackBar />
    </div>
  );
}
