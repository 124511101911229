import React from "react";
import HeaderInner from "./HeaderInner";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Button, InputAdornment, TextField } from "@material-ui/core";
import configureStore from "../../../store/configureStore";
import { create_lead } from "./actions/action-lead";
import { useDispatch } from "react-redux";
import Footer from "./Footer";
import { history } from "../../../reducers";
import CommonSnackBar from "../../common/CommonSnackBar";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
  },
  input: {
    marginBottom: 10,
    background: "#fff",
  },
}));

export default function AddLead() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeOuid, activeOuidChain } = store.getState().permission;
  const [error, setError] = React.useState();

  const [state, setState] = React.useState({
    companyName: "",
    contactFullName: " ",
    contactPhoneNumber: "",
    contactEmail: "",
    companyWebsite: "",
    handler: "",
    status: "done",
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.id]: event.target.value });
    console.log("lead", state);
  };

  function validatePhoneNumber(input_str) {
    console.log("input_str", input_str);
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    return re.test(input_str);
  }

  function validateWebsite(url) {
    const re = /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,4}\/?$/;
    return re.test(url);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validatePhoneNumber(state.contactPhoneNumber)) {
      setError("Phone number not valid");
      return;
    }

    if (state.companyWebsite && !validateWebsite(state.companyWebsite)) {
      setError("Website URL not valid");
      return;
    }

    dispatch(create_lead(state, activeOuid));
    history.push(`/${activeOuid}/contacts`);
  };

  return (
    <div>
      <HeaderInner pageTitle="Add Lead" />
      <div className="AppPage container--narrow mt">
        <h1 className="primary_heading">Leads Details</h1>
        <p className="sub_text ">
          Sales leads can be tracked through CRM system, enabling sales teams to
          input, track and analyze data for leads in one place.
        </p>

        <form className="add_wrap" onSubmit={handleSubmit}>
          {error && <div className="form_error">{error}</div>}
          <TextField
            id="contactFullName"
            label="Full Name"
            fullWidth
            variant="outlined"
            className={classes.input}
            onChange={handleChange}
            required
          />
          <TextField
            id="companyName"
            label="Company Name"
            fullWidth
            variant="outlined"
            className={classes.input}
            onChange={handleChange}
          />
          <TextField
            id="contactPhoneNumber"
            label="Phone Number"
            fullWidth
            variant="outlined"
            className={classes.input}
            onChange={handleChange}
            required
            type="tel"
            inputProps={{
              maxLength: 12,
              inputMode: "tel",
            }}
          />

          <TextField
            id="contactEmail"
            label="Email Address"
            fullWidth
            variant="outlined"
            className={classes.input}
            onChange={handleChange}
            required
            type="email"
          />
          <TextField
            id="companyWebsite"
            label="Website"
            fullWidth
            variant="outlined"
            className={classes.input}
            onChange={handleChange}
          />
          <div className="AppFloatingButtonWrapper t-c">
            <Button
              type="submit"
              className="primary_btn"
              fullWidth
              variant="contained"
              color="primary"
            >
              Add lead
            </Button>
          </div>
        </form>
      </div>
      <Footer />
      <CommonSnackBar />
    </div>
  );
}
