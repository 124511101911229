import {
  CREATE_LEAD_REQUESTED,
  CREATE_LEAD_SUCCESS,
  CREATE_LEAD_ERRORED,
  GET_LEADS_REQUESTED,
  GET_LEADS_SUCCESS,
  GET_LEADS_ERRORED,
  GET_LEAD_DETAILS_REQUESTED,
  GET_LEAD_DETAILS_SUCCESS,
  GET_LEAD_DETAILS_ERRORED,
  UPDATE_LEAD_REQUESTED,
  UPDATE_LEAD_SUCCESS,
  UPDATE_LEAD_ERRORED,
  DELETE_LEAD_REQUESTED,
  DELETE_LEAD_SUCCESS,
  DELETE_LEAD_ERRORED,
} from "../constant/action-types";

const defaultState = {
  showGlobalLoader: false,
  leads: [],
  error: {},
  leaddetails: {},
};

export function lead(state = defaultState, { type, payload }) {
  switch (type) {
    case CREATE_LEAD_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case CREATE_LEAD_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case CREATE_LEAD_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_LEADS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_LEADS_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        leads: payload,
      };

    case GET_LEADS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    case GET_LEAD_DETAILS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_LEAD_DETAILS_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        leaddetails: payload,
      };

    case GET_LEAD_DETAILS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    case UPDATE_LEAD_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case UPDATE_LEAD_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case UPDATE_LEAD_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    case DELETE_LEAD_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case DELETE_LEAD_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        leads: payload,
      };

    case DELETE_LEAD_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    default:
      return state;
  }
}
