import React, { useEffect } from "react";
import HeaderInner from "./HeaderInner";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField } from "@material-ui/core";
import configureStore from "../../../store/configureStore";
import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux";
import { get_meeting_details, update_meeting } from "./actions/action-meeting";
import { get_lead } from "./actions/action-lead";
import Loader from "../../common/Loader";
import { history } from "../../..//reducers";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
  },
  input: {
    marginBottom: 10,
    background: "#fff",
    width: "100%",
  },
  formControl: {
    marginBottom: 10,
    background: "#fff",
    width: "100%",
  },
}));


export default function EditMeeting() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeOuid } = store.getState().permission;
  const { showGlobalLoader } = store.getState().meeting;

  const [state, setState] = React.useState({
    id: "",
    leadId: "",
    leadContactFullName: "",
    meetingDescription: "",
    startTime: "",
    endTime: "",
    date: "",
    meetingAddress: "",
  });

  const convertNumberToDate = (defaultDate) => {
    if (!defaultDate || defaultDate.length !== 8) return ""; 
    let year = defaultDate.substring(0, 4);
    let month = defaultDate.substring(4, 6);
    let date = defaultDate.substring(6, 8);
    return `${year}-${month}-${date}`;
  };

  const convertNumberToTime = (time) => {
    if (!time) return "";
    let timeString = time.toString().padStart(4, "0");
    let hours = timeString.substring(0, 2);
    let minutes = timeString.substring(2, 4);
    return `${hours}:${minutes}`; 
  };

  const handleDatetimeChange = (event) => {
    const { id, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const validateAndFormatData = () => {
    if (!state.date || !state.startTime || !state.endTime) {
      alert("Please fill in all the required fields.");
      return null;
    }

    const formattedDate = parseInt(state.date.replace(/-/g, ""), 10); 
    const formattedStartTime = parseInt(state.startTime.replace(":", ""), 10); 
    const formattedEndTime = parseInt(state.endTime.replace(":", ""), 10); 
    return {
      ...state,
      date: formattedDate,
      startTime: formattedStartTime,
      endTime: formattedEndTime,
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedState = validateAndFormatData();
    if (!updatedState) return;

    console.log("Submitting updated state:", updatedState); 
    await dispatch(update_meeting(updatedState, activeOuid, state.id));
    
    history.push(`/${activeOuid}/meeting`);
  };

  useEffect(() => {
    let meetingId = window.location.pathname.split("/")[3];
    const fetchData = async () => {
      await dispatch(get_meeting_details(meetingId, activeOuid));
      await dispatch(get_lead(activeOuid));

      const meetingDetails = store.getState().meeting.meetingDetails;

      if (meetingDetails) {
        const formattedDate = convertNumberToDate(meetingDetails.date.toString());
        const formattedStartTime = convertNumberToTime(meetingDetails.startTime);
        const formattedEndTime = convertNumberToTime(meetingDetails.endTime);

        setState({
          ...state,
          id: meetingDetails.id,
          leadId: meetingDetails.leadId,
          leadContactFullName: meetingDetails.leadContactFullName,
          meetingDescription: meetingDetails.meetingDescription,
          startTime: formattedStartTime, 
          endTime: formattedEndTime, 
          date: formattedDate,
          meetingAddress: meetingDetails.meetingAddress,
        });
      }
    };
    fetchData();
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Edit Meeting" />
      <div className="HrmAppPage container--narrow mt">
        {showGlobalLoader ? (
          <Loader />
        ) : (
          <form className="add_wrap" onSubmit={handleSubmit}>
            <TextField
              id="leadContactFullName"
              label="Full Name"
              fullWidth
              disabled
              required
              variant="outlined"
              value={state.leadContactFullName}
              className={classes.input}
              onChange={handleDatetimeChange}
              inputProps={{
                style: {
                  textTransform: "capitalize",
                  color: "#131313",
                },
              }}
            />
            <h4 className="form_heading">Meeting Details</h4>
            <TextField
              required
              id="meetingDescription"
              label="Description"
              fullWidth
              variant="outlined"
              value={state.meetingDescription}
              className={classes.input}
              onChange={handleDatetimeChange}
            />
            <TextField
              id="date"
              label="Start Date"
              type="date"
              variant="outlined"
              required
              value={state.date}
              className={classes.input}
              onChange={handleDatetimeChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="startTime"
              label="Start Time"
              type="time"
              required
              variant="outlined"
              value={state.startTime}
              className={classes.input}
              onChange={handleDatetimeChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 60, 
              }}
            />
            <TextField
              id="endTime"
              label="End Time"
              type="time"
              required
              variant="outlined"
              value={state.endTime}
              className={classes.input}
              onChange={handleDatetimeChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 60, 
              }}
            />
            <TextField
              id="meetingAddress"
              label="Address"
              fullWidth
              variant="outlined"
              value={state.meetingAddress}
              className={classes.input}
              onChange={handleDatetimeChange}
            />
            <div className="AppFloatingButtonWrapper t-c">
              <Button
                type="submit"
                className="primary_btn"
                fullWidth
                variant="contained"
                color="primary"
              >
                Update
              </Button>
            </div>
          </form>
        )}
      </div>
      <Footer />
    </div>
  );
}
