import React, { useState } from "react";
import { Link } from "react-router-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import profimg from "../images/pro.png";
import { Avatar, Drawer } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import configureStore from "../../../../store/configureStore";
import EditIcon from "@material-ui/icons/Edit";
import { history } from "../../../../reducers";
import { delete_note } from "../actions/action-notes";
import { useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  avatar: {
    textTransform: "uppercase",
  },
}));

export default function NotesCard(props) {
  const [isDrawerOpen, setIsDrawerOPen] = useState(false);
  const { activeOuid } = store.getState().permission;
  const dispatch = useDispatch();
  const classes = useStyles();

  // const handleDelete = (leadId) => {
  //   var leadData = props.item;
  //   leadData.status = 'done';
  //   console.log("leadData", leadId);
  //   dispatch(delete_lead(leadData, activeOuid, leadId));
  // }

  const handleDetele = () => {
    console.log("props.item.username delete", props.item.id);
    dispatch(delete_note(activeOuid, props.item.id));
  };

  return (
    <div className="notes_card ">
      <div className="profile_top">
        <Link
          to={`/${activeOuid}/notes-details/${props.item.id}`}
          className="profile_left"
        >
          <div className="profile_img">
            <Avatar
              className={classes.avatar}
              style={{
                backgroundColor: "#214B87",
              }}
            >
              {props.item.leadContactFullName &&
                props.item.leadContactFullName.charAt(0)}
            </Avatar>
          </div>
          <div className="profile_details">
            <h6 className="profile_name">{props.item.leadContactFullName}</h6>
            {/* <div className="profile_company_name">
              {props.item.leadContactFullName}
            </div> */}
          </div>
        </Link>

        <div className="profile_right">
          {/* <span className="schedule_card_date">
            12-06-2022 {props.item.noteDateTime}
          </span> */}
        </div>
      </div>
      <Link
        to={`/${activeOuid}/notes-details/${props.item.id}`}
        className="card_desc mt"
      >
        {props.item.noteContent}
      </Link>
      <div className="schedule_card_bottom">
        <div className="schedule_card_bottom_left">
          {/* <span className="schedule_card_time">05:15 pm</span> */}
        </div>
        <div className="schedule_card_bottom_right">
          <button
            className="card_option_btn"
            onClick={() => setIsDrawerOPen(true)}
          >
            <MoreVertIcon />
          </button>
          <Drawer
            className="edit_option_btn_drawer"
            anchor="bottom"
            open={isDrawerOpen}
            onClose={() => setIsDrawerOPen(false)}
          >
            <div className="edit_option_head">options</div>
            <ul className="edit_option_tools_wrap">
              <li
                className="edit_option_tools_item"
                onClick={() => setIsDrawerOPen(false)}
              >
                <div className="edit_option">
                  <EditIcon
                    onClick={() =>
                      history.push(`/${activeOuid}/edit-notes/${props.item.id}`)
                    }
                  />
                  <span className="edit_option_tools_item_text">Edit</span>
                </div>
              </li>
              <li
                className="edit_option_tools_item"
                onClick={() => setIsDrawerOPen(false)}
              >
                <div className="edit_option">
                  <DeleteIcon onClick={() => handleDetele()} />
                  <span className="edit_option_tools_item_text">Delete</span>
                </div>
              </li>
            </ul>
          </Drawer>
        </div>
      </div>
    </div>
  );
}
