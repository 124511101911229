import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderInner from "./HeaderInner";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import { get_meeting_details } from "./actions/action-meeting";
import configureStore from "../../../store/configureStore";
import Footer from "./Footer";
import Loader from "../../common/Loader";
import { Avatar } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { history } from "../../../reducers";
import { Link } from "react-router-dom";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  avatar: {
    textTransform: "uppercase",
  },
}));

function MeetingDetails() {
  const classes = useStyles();
  const { activeOuid } = store.getState().permission;
  const dispatch = useDispatch();
  const { meetingDetails, showGlobalLoader } = useSelector(
    (state) => state.meeting
  );

  function dateFormat(value) {
    var date = value.toString();
    return date.replace(/(\d{4})(\d{2})(\d{2})/g, "$3-$2-$1");
  }

  const convertFrom24To12Format = (time24) => {
    const [sHours, minutes] = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
    const period = +sHours < 12 ? "AM" : "PM";
    const hours = +sHours % 12 || 12;

    return `${hours}:${minutes} ${period}`;
  };

  function addStr(str, index, stringToAdd) {
    if (str.length === 3) {
      str = "0" + str;
    }
    return (
      str.substring(0, index) + stringToAdd + str.substring(index, str.length)
    );
  }

  let meetingId;
  meetingId = window.location.pathname.split("/");
  meetingId = meetingId[3];

  useEffect(() => {
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_meeting_details(meetingId, activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Note Details " />
      <div className="HrmAppPage container--narrow mt">
        {showGlobalLoader ? (
          <Loader />
        ) : (
          <div className="notes_details_wrap">
            <div className="profile_top">
              <div className="profile_left">
                <div className="profile_img">
                  <Avatar
                    className={classes.avatar}
                    style={{
                      backgroundColor: "#214B87",
                    }}
                  >
                    {meetingDetails.leadContactFullName &&
                      meetingDetails.leadContactFullName.charAt(0)}
                  </Avatar>
                </div>
                <div className="profile_details">
                  <Link
                    to={`/${activeOuid}/contact-profile/${meetingDetails.leadId}`}
                    className="profile_name"
                  >
                    {meetingDetails.leadContactFullName}
                  </Link>
                  {/* <div className="profile_company_name">shadi.com</div> */}
                </div>
              </div>

              <div className="profile_right">
                <span className="schedule_card_date">
                  {meetingDetails.date &&
                    dateFormat(meetingDetails.date, "####-##-##")}
                </span>
              </div>
            </div>
            <p className="note_detail_desc mt">
              {meetingDetails.meetingDescription}
            </p>
            {meetingDetails.startTime && (
              <div className="schedule_time_wrap">
                <div className="schedule_time_range">
                  <span className="schedule_card_time">
                    {convertFrom24To12Format(
                      addStr(meetingDetails.startTime.toString(), 2, ":")
                    )}
                  </span>
                  <span className="schedule_to">to</span>
                  <span className="schedule_card_time">
                    {convertFrom24To12Format(
                      addStr(meetingDetails.endTime.toString(), 2, ":")
                    )}
                  </span>
                </div>
              </div>
            )}
            <div className="note_details_bottom">
              <Link to={`/${activeOuid}/edit-meeting/${meetingDetails.id}`}>
                <BorderColorIcon />
              </Link>

              {/* <span className="schedule_card_time">
                {meetingDetails.date &&
                  dateFormat(meetingDetails.date, "####-##-##")}
              </span> */}
            </div>
          </div>
        )}
        <Footer />
      </div>
    </div>
  );
}

export default MeetingDetails;
