import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderInner from "./HeaderInner";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import profimg from "./images/pro.png";
import { get_note, get_note_details } from "./actions/action-notes";
import configureStore from "../../../store/configureStore";
import Footer from "./Footer";
import Loader from "../../common/Loader";
import { Avatar } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { history } from "../../../reducers";
import { Link } from "react-router-dom";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  avatar: {
    textTransform: "uppercase",
  },
}));

export default function NotesDetails() {
  const classes = useStyles();
  const { activeOuid } = store.getState().permission;
  const dispatch = useDispatch();
  const { noteDetails, showGlobalLoader } = useSelector((state) => state.note);

  let noteId;
  noteId = window.location.pathname.split("/");
  noteId = noteId[3];

  useEffect(() => {
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_note_details(noteId, activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Note Details " />
      <div className="HrmAppPage container--narrow mt">
        {showGlobalLoader ? (
          <Loader />
        ) : (
          <div className="notes_details_wrap">
            <div className="profile_top">
              <div className="profile_left">
                <div className="profile_img">
                  <Avatar
                    className={classes.avatar}
                    style={{
                      backgroundColor: "#214B87",
                    }}
                  >
                    {noteDetails.leadContactFullName &&
                      noteDetails.leadContactFullName.charAt(0)}
                  </Avatar>
                </div>
                <div className="profile_details">
                  <Link
                    to={`/${activeOuid}/contact-profile/${noteDetails.leadId}`}
                    className="profile_name"
                  >
                    {noteDetails.leadContactFullName}
                  </Link>
                  {/* <div className="profile_company_name">shadi.com</div> */}
                </div>
              </div>

              <div className="profile_right">
                {/* <span className="schedule_card_date">12-06-2022</span> */}
              </div>
            </div>
            <p className="note_detail_desc mt">{noteDetails.noteContent}</p>
            <div className="note_details_bottom">
              <Link to={`/${activeOuid}/edit-notes/${noteDetails.id}`}>
                <BorderColorIcon />
              </Link>

              {/* <span className="schedule_card_time">05:15 pm</span> */}
            </div>
          </div>
        )}
        <Footer />
      </div>
    </div>
  );
}
